<!-- Hero Start -->
<section class="bg-half d-table w-100" id="home" style="background: url('assets/images/banner/bs2.jpg');">
    <div class="bg-overlay"></div>
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-12 text-center">
          <div class="page-next-level title-heading">
            <h1 class="text-white title-dark"> Enterprise Application Services </h1>
            <p class="text-white-50 para-desc mb-0 mx-auto">Application services include app development, support, cloud migration, modernization, integration,  and  700+ experts onboard, octohub brings all-around services to turn your enterprise applications into max profit.</p>
            <div class="page-next">
              <nav aria-label="breadcrumb" class="d-inline-block">
                <ul class="breadcrumb bg-white rounded shadow mb-0">
                  <li class="breadcrumb-item"><a routerLink="/index">Octohub</a></li>
                  <li class="breadcrumb-item"><a href="javascript:void(0)">Services</a></li>

                  <li class="breadcrumb-item active" aria-current="page">Enterprise Application Services</li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
        <!--end col-->
      </div>
      <!--end row-->
    </div>
    <!--end container-->
  </section>
  <!--end section-->
  <div class="position-relative">
    <div class="shape overflow-hidden text-white">
      <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
      </svg>
    </div>
  </div>
  <!-- Hero End -->
  
  <section class="section">
    <div class="container">
      <div class="row align-items-center" id="counter">
       
        <!--end col-->
  
        <div class="col-md-12 mt-4 pt-2 mt-sm-0 pt-sm-0">
          <div class="ms-lg-4">
            
            <div class="section-title text-center">
              <h4 class="title mb-4">Enterprise Application Services </h4>
              <p class="text-muted">An enterprise application that can automate a process or a few processes can create remarkable value to your organization and to its users, releasing productive time for them. It can also have the power to result in considerable savings for your business. Over the past 14 years, octohub has gained considerable reputation for its web application services. As your trusted application services provider, we offer a wide range of services including custom web application services, hybrid applications that involve development in the cloud and deployment on the edge.</p>
  
                <p class="text-muted">
                    We design and deliver, enterprise  application & web, mobile and cloud business applications of different complexity and for various needs. In the process, we pay special attention to their flexibility, speed and integration potential. improve customer satisfaction and achieve competitive differentiation for sustainable business growth.                </p>
                <p class="text-muted">
                    At octohub , we enable our customers to optimise their business processes and result in significant cost savings. Through our engagement, we have leveraged innovation to improve customer satisfaction and achieve competitive differentiation for sustainable business growth.                 </p>

                 <p class="text-muted">
                    We have a deep understanding of business cases across industries and domains. Our technical expertise and delivery capability allow us to develop and maintain custom business applications with the highest quality standards. We adopt a transparent approach that ensures continuous communication and collaboration that assures our clients about the processes we follow and the project status.                 </p>

                  


            </div>
          </div>
        </div>
        <!--end col-->
      </div>
      <!--end row-->
    </div>
    <!--end container-->
  
    <!--end container-->
  </section>
  

 <!-- Shape Start -->
 <div class="position-relative">
  <div class="shape overflow-hidden ">
    <svg viewBox="0 0 2880 48" fill="none" style="color:#d3d3d3 ;" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
    </svg>
  </div>
</div>
<!--Shape End-->
  <!--end section-->
  <!-- End -->
  
  <!-- Back to top -->
  <a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
    <i-feather name="arrow-up" class="icons"></i-feather>
  </a>
  <!-- Back to top -->