import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-index-portfolio',
  templateUrl: './index-portfolio.component.html',
  styleUrls: ['./index-portfolio.component.css']
})

/**
 * Portfolio Component
 */
export class IndexPortfolioComponent implements OnInit {
  
  hideFooter = true;

  filterredImages;
  galleryFilter = 'all';
  list = [{
    image: 'assets/images/real/event-01.JPG',
    title: 'Broucher Launch',
    type: 'Avasa Homes',
    category: 'Events'
  },
  {
    image: 'assets/images/real/event-02.JPG',
    title: 'Broucher Launch',
    type: 'Avasa Homes',
    category: 'Events'
  },
  {
    image: 'assets/images/real/event-03.JPG',
    title: 'Broucher Launch',
    type: 'Avasa Homes',
    category: 'Events'
  },
  {
    image: 'assets/images/real/event-04.JPG',
    title: 'Broucher Launchs',
    type: 'Avasa Homes',
    category: 'Events'
  },
  {
    image: 'assets/images/real/event-05.JPG',
    title: 'Broucher Launch',
    type: 'Avasa Homes',
    category: 'Events'
  },
  {
    image: 'assets/images/real/event-06.JPG',
    title: 'Broucher Launch',
    type: 'Avasa Homes',
    category: 'Events'
  },
  {
    image: 'assets/images/real/event-06.JPG',
    title: 'Broucher Launch',
    type: 'Avasa Homes',
    category: 'Events'
  },
  {
    image: 'assets/images/real/event-06.JPG',
    title: 'Broucher Launch',
    type: 'Avasa Homes',
    category: 'Events'
  },
  // Project Banners 
  {
    image: 'assets/images/real/b1.jpg',
    title: 'Broucher Launch',
    type: 'Avasa Homes',
    category: 'Avasa Homes'
  },
  {
    image: 'assets/images/real/b2.jpg',
    title: 'Broucher Launch',
    type: 'Avasa Homes',
    category: 'Avasa Homes'
  },
  {
    image: 'assets/images/real/b3.jpg',
    title: 'Broucher Launchs',
    type: 'Avasa Homes',
    category: 'Avasa Homes'
  },
  {
    image: 'assets/images/real/b4.jpg',
    title: 'Broucher Launch',
    type: 'Avasa Homes',
    category: 'Avasa Homes'
  },
  {
    image: 'assets/images/real/b5.jpg',
    title: 'Broucher Launch',
    type: 'Avasa Homes',
    category: 'Avasa Homes'
  },
  {
    image: 'assets/images/real/b6.jpg',
    title: 'Broucher Launch',
    type: 'Avasa Homes',
    category: 'Avasa Homes'
  },
  {
    image: 'assets/images/real/b7.jpg',
    title: 'Broucher Launch',
    type: 'Avasa Homes',
    category: 'Avasa Homes'
  },
  {
    image: 'assets/images/real/b8.jpg',
    title: 'Broucher Launch',
    type: 'Avasa Homes',
    category: 'Avasa Homes'
  }];

  constructor() { }

  ngOnInit(): void {
    this.filterredImages = this.list;
  }

  /**
   * Filter Class Add
   */
  activeCategory(category) {
    this.galleryFilter = category;
    if (this.galleryFilter === 'all') {
      this.filterredImages = this.list;
    } else {
      this.filterredImages = this.list.filter(x => x.category === this.galleryFilter);
    }
  }
}
