<!-- Hero Start -->
<section class="bg-half d-table w-100" id="home" style="background: url('assets/images/banner/bs4.jpg');">
    <div class="bg-overlay"></div>
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-12 text-center">
          <div class="page-next-level title-heading">
            <h1 class="text-white title-dark"> Data Analytics Services </h1>
            <p class="text-white-50 para-desc mb-0 mx-auto"> Data analysis services allow businesses to get their data collected, processed and presented to them in the form of actionable insights while avoiding investments in the development and administration of an analytics solution.</p>
            <div class="page-next">
              <nav aria-label="breadcrumb" class="d-inline-block">
                <ul class="breadcrumb bg-white rounded shadow mb-0">
                  <li class="breadcrumb-item"><a routerLink="/index">Octohub</a></li>
                  <li class="breadcrumb-item"><a href="javascript:void(0)">Services</a></li>

                  <li class="breadcrumb-item active" aria-current="page">Data Analytics Services</li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
        <!--end col-->
      </div>
      <!--end row-->
    </div>
    <!--end container-->
  </section>
  <!--end section-->
  <div class="position-relative">
    <div class="shape overflow-hidden text-white">
      <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
      </svg>
    </div>
  </div>
  <!-- Hero End -->
  
  <section class="section">
    <div class="container">
      <div class="row align-items-center" id="counter">
       
        <!--end col-->
  
        <div class="col-md-12 mt-4 pt-2 mt-sm-0 pt-sm-0">
          <div class="ms-lg-4">
            
            <div class="section-title text-center">
              <h4 class="title mb-4">Data Analytics Services </h4>
              <p class="text-muted">Envision, design and modernize your data environment. Our data-driven insights help you protect existing investments and discover future business opportunities.</p>
  
                <p class="text-muted">
                    Octohub delivered true intelligence that normally needs to be extracted from contrasting sources of data. We ideate around how to leverage your data, capture new data, uncover newer dimensions of your data and extract intelligence out of it. At a time when data is the new oil, developing insights to enable smart decisions is fundamental to any business.                </p>
                <p class="text-muted">                
                    Big data and analytics are no longer the stuff of the board room.Octohub  help design solutions that bring actionable insights which improve organization’s performance and enhance decision making.
                </p>

                <p class="text-muted">                
                    Our expertise in Business Analytics, Business Intelligence (BI), and Visualization can help you derive deeper actionable insights on your customers’ behaviour so you can optimise your business offerings according to the touchpoints.                </p>


            </div>
          </div>
        </div>
        <!--end col-->
      </div>
      <!--end row-->
    </div>
    <!--end container-->
  
    <!--end container-->
  </section>
  

 <!-- Shape Start -->
 <div class="position-relative">
  <div class="shape overflow-hidden ">
    <svg viewBox="0 0 2880 48" fill="none" style="color:#d3d3d3 ;" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
    </svg>
  </div>
</div>
<!--Shape End-->
  <!--end section-->
  <!-- End -->
  
  <!-- Back to top -->
  <a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
    <i-feather name="arrow-up" class="icons"></i-feather>
  </a>
  <!-- Back to top -->