<!-- Hero Start -->
<section class="bg-half-170 pb-0 bg-light d-table w-100" id="home">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-10">
          <div class="title-heading text-center mt-4 mt-md-5 pt-md-5">
            <div class="alert alert-light alert-pills" role="alert">
              <span class="badge rounded-pill bg-success me-1">Start</span>
              <span class="content"> Fill job <a routerLink="/job-apply" class="text-primary">application</a> now
                !</span>
            </div>
  
            <h1 class="heading mb-3">
              <ngx-typed-js [strings]="['Freelancer', 'Developer', 'Designer', 'UX Developer', 'ios Developer']"
                [shuffle]="true" [typeSpeed]="80" [loop]="true">
                Job Vacancies for <span class="element text-primary typing"></span>
              </ngx-typed-js>
            </h1>
            <p class="para-desc mx-auto text-muted">Huge community of designers and developers ready for your project.</p>
            <form class="rounded p-4 mt-4 bg-white">
              <div class="row text-start">
                <div class="col-lg-5 col-md-4">
                  <div class="mb-0">
                    <input type="text" class="form-control" required placeholder="Keywords">
                  </div>
                </div>
                <!--end col-->
  
                <div class="col-lg-7 col-md-8">
                  <div class="row align-items-center">
                    <div class="col-md-4 mt-4 mt-sm-0">
                      <div class="mb-0">
                        <select class="form-control custom-select">
                          <option selected="">Location</option>
                          <option value="AF">Afghanistan</option>
                          <option value="AX">&Aring;land Islands</option>
                          <option value="AL">Albania</option>
                          <option value="DZ">Algeria</option>
                          <option value="AS">American Samoa</option>
                          <option value="AD">Andorra</option>
                          <option value="AO">Angola</option>
                          <option value="AI">Anguilla</option>
                          <option value="AQ">Antarctica</option>
                        </select>
                      </div>
                    </div>
                    <!--end col-->
  
                    <div class="col-md-4 mt-4 mt-sm-0">
                      <div class="mb-0">
                        <select class="form-control custom-select">
                          <option selected="">Job Types</option>
                          <option value="4">Accounting</option>
                          <option value="1">IT & Software</option>
                          <option value="3">Marketing</option>
                          <option value="5">Banking</option>
                        </select>
                      </div>
                    </div>
                    <!--end col-->
  
                    <div class="col-md-4 mt-4 mt-sm-0">
                      <div class="d-grid">
                        <input type="submit" id="search" name="search" class="searchbtn btn btn-primary" value="Search">
                      </div>
                    </div>
                    <!--end col-->
                  </div>
                  <!--end row-->
                </div>
                <!---end col-->
              </div>
              <!--end row-->
            </form>
            <p class="text-muted mb-0 mt-4"><span class="text-dark">Keywords :</span> Web Developer, Web Designer, Php
              Developer, IOS Developer, etc...</p>
          </div>
        </div>
        <!--end col-->
  
        <div class="col-12 mt-4 pt-3">
          <img src="assets/images/job/home.png" class="img-fluid" alt="">
        </div>
        <!--end col-->
      </div>
      <!--end row-->
    </div>
    <!--end container-->
  </section>
  <!--end section-->
  <!-- Hero End -->
  
  <!-- Shape Start -->
  <div class="position-relative">
    <div class="shape overflow-hidden text-white">
      <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
      </svg>
    </div>
  </div>
  <!--Shape End-->
  
  <!-- Start -->
  <section class="section">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-12 text-center">
          <div class="section-title mb-4 pb-2">
            <h4 class="title mb-4">Popular Catagories</h4>
            <p class="text-muted para-desc mx-auto mb-0">Start working with <span
                class="text-primary fw-bold">Landrick</span> that can provide everything you need to generate
              awareness, drive traffic, connect.</p>
          </div>
        </div>
        <!--end col-->
      </div>
      <!--end row-->
  
      <div class="row">
        <ng-container *ngIf="categoryData!==null">
          <div class="col-lg-3 col-md-6 col-12 mt-4 pt-2" *ngFor="let item of categoryData">
            <div class="card categories overflow-hidden rounded shadow border-0">
              <img src="assets/images/job/cate1.jpg" class="img-fluid" alt="{{item.title}}">
              <div class="card-body">
                <ul class="list-unstyled d-flex justify-content-between mb-0">
                  <li><a routerLink="all-jobs/{{item.id}}" class="title h6 text-dark">{{item.title}}</a></li>
                  <li class="h6 mb-0 jobs">{{item.jobCount}} Jobs</li>
                </ul>
              </div>
            </div>
          </div>
        </ng-container>
     
        <!--end col-->
  
  
      
      </div>
      <!--end row-->
    </div>
    <!--end container-->
  
    <!-- Companies Start -->
    <div class="container mt-100 mt-60">
      <div class="row justify-content-center">
        <div class="col-12 text-center">
          <div class="section-title mb-4 pb-2">
            <h4 class="title mb-4">Featured Jobs</h4>
            <p class="text-muted para-desc mx-auto mb-0">Start working with <span
                class="text-primary fw-bold">Landrick</span> that can provide everything you need to generate awareness,
              drive traffic, connect.</p>
          </div>
        </div>
        <!--end col-->
      </div>
      <!--end row-->
  
      <div class="row">
        <ng-container *ngIf="jobData!==null">

        </ng-container>
        <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2" *ngFor="let item of jobData">
          <a routerLink="/job-detail/{{item.result.id}}">
            <div class="d-flex key-feature align-items-center p-3 rounded shadow">
              <img src="assets/images/job/Circleci.svg" class="avatar avatar-ex-sm" alt="{{item.result.job_Title}}">
              <div class="flex-1 ms-3">
                <h4 class="title mb-0 text-dark">{{item.result.job_Title}}</h4>
                <p class="text-muted mb-0">{{item.result.address}}</p>
              </div>
            </div>
          </a>
        </div>
        <!--end col-->
  
  
  
        <div class="col-12 text-center mt-4 pt-2">
          <a routerLink="/all-jobs" class="btn btn-primary">See All Jobs <i
              class="uil uil-angle-right-b align-middle"></i></a>
        </div>
      </div>
      <!--end row-->
    </div>
    <!--end container-->
    <!-- Companies End -->
  
    <!-- Candidates Start -->
    <div class="container mt-100 mt-60">
      <div class="row justify-content-center">
        <div class="col-12 text-center">
          <div class="section-title mb-4 pb-2">
            <h4 class="title mb-4">Expert Candidates</h4>
            <p class="text-muted para-desc mx-auto mb-0">Start working with <span
                class="text-primary fw-bold">Landrick</span> that can provide everything you need to generate
              awareness, drive traffic, connect.</p>
          </div>
        </div>
        <!--end col-->
      </div>
      <!--end row-->
      <app-member [memberData]="memberData"></app-member>
    </div>
    <!--end container-->
    <!-- Candidates End -->
  
    <!-- Testi start -->
    <!--<div class="container mt-100 mt-60">
      <div class="row justify-content-center">
        <div class="col-12 text-center">
          <div class="section-title mb-4 pb-2">
            <h4 class="title mb-4">Candidates Review</h4>
            <p class="text-muted para-desc mx-auto mb-0">Start working with <span
                class="text-primary fw-bold">Landrick</span> that can provide everything you need to generate
              awareness, drive traffic, connect.</p>
          </div>
        </div>
      </div>
  
      <div class="row">
        <div class="col-12 mt-4 pt-2">
          <owl-carousel-o id="customer-testi" [options]="customOptions">
            <ng-template carouselSlide>
              <div class="card customer-testi border-0 text-center">
                <div class="card-body">
                  <img src="assets/images/client/01.jpg"
                    class="img-fluid avatar avatar-small rounded-circle mx-auto shadow" alt="">
                  <p class="text-muted mt-4">" It seems that only fragments of the original text remain in the Lorem Ipsum
                    texts used today. "</p>
                  <h6 class="text-primary">- Thomas Israel</h6>
                </div>
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="card customer-testi border-0 text-center">
                <div class="card-body">
                  <img src="assets/images/client/02.jpg"
                    class="img-fluid avatar avatar-small rounded-circle mx-auto shadow" alt="">
                  <p class="text-muted mt-4">" The most well-known dummy text is the 'Lorem Ipsum', which is said to have
                    originated in the 16th century. "</p>
                  <h6 class="text-primary">- Carl Oliver</h6>
                </div>
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="card customer-testi border-0 text-center">
                <div class="card-body">
                  <img src="assets/images/client/03.jpg"
                    class="img-fluid avatar avatar-small rounded-circle mx-auto shadow" alt="">
                  <p class="text-muted mt-4">" One disadvantage of Lorum Ipsum is that in Latin certain letters appear
                    more frequently than others. "</p>
                  <h6 class="text-primary">- Barbara McIntosh</h6>
                </div>
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="card customer-testi border-0 text-center">
                <div class="card-body">
                  <img src="assets/images/client/04.jpg"
                    class="img-fluid avatar avatar-small rounded-circle mx-auto shadow" alt="">
                  <p class="text-muted mt-4">" Thus, Lorem Ipsum has only limited suitability as a visual filler for
                    German texts. "</p>
                  <h6 class="text-primary">- Jill Webb</h6>
                </div>
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="card customer-testi border-0 text-center">
                <div class="card-body">
                  <img src="assets/images/client/05.jpg"
                    class="img-fluid avatar avatar-small rounded-circle mx-auto shadow" alt="">
                  <p class="text-muted mt-4">" There is now an abundance of readable dummy texts. These are usually used
                    when a text is required. "</p>
                  <h6 class="text-primary">- Dean Tolle</h6>
                </div>
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="card customer-testi border-0 text-center">
                <div class="card-body">
                  <img src="assets/images/client/06.jpg"
                    class="img-fluid avatar avatar-small rounded-circle mx-auto shadow" alt="">
                  <p class="text-muted mt-4">" According to most sources, Lorum Ipsum can be traced back to a text
                    composed by Cicero. "</p>
                  <h6 class="text-primary">- Christa Smith</h6>
                </div>
              </div>
            </ng-template>
          </owl-carousel-o>
        </div>
      </div>
    </div>-->
    <!--end container-->
    <div class="container pt-5">
      <app-clients-logo></app-clients-logo>
      <!--end row-->
    </div>
    <!--end container-->
    <!-- Testi end -->
  </section>
  <!--end section-->
  <!-- End -->
  
  <!-- Shape Start -->
  <div class="position-relative">
    <div class="shape overflow-hidden ">
      <svg viewBox="0 0 2880 48" fill="none" style="color:#d3d3d3 ;" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
      </svg>
    </div>
  </div>
  <!--Shape End-->
  
  <!-- Back to top -->
  <a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
    <i-feather name="arrow-up" class="icons"></i-feather>
  </a>
  <!-- Back to top -->