
<!--<app-switcher></app-switcher>-->
<!-- <div *ngIf="isLoading" class="spinner-wrapper" style="text-align: center; margin-top: 100px; ">
    <img style="width: 10%;"  src="../assets/images/real/logo_01.png">
</div> -->
<div>
<!-- <div  *ngIf="!isLoading"> -->

<router-outlet></router-outlet>

<modal-outlet></modal-outlet>

<app-carousel></app-carousel>  
</div>

