import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { Observable } from 'rxjs';
import ICategory, { ISubcategory } from '../Interfaces/category.interface';
import { ICompany } from '../Interfaces/company.interface';
import { IEmpType } from '../Interfaces/employee.interface';
import { IJob, IJobType } from '../Interfaces/job.interface';
import { CategoryService } from '../services/category.service';
import { CompanyService } from '../services/company.service';
import { EmployeeService } from '../services/employee.service';
import { JobService } from '../services/job.service';

@Component({
  selector: 'app-job',
  templateUrl: './job.component.html',
  styleUrls: ['./job.component.css']
})
export class JobComponent implements OnInit {

/***
   * Footer bg color set
   */


 /**
 * Member Data
 */
 memberData = [
   {
     profile: "assets/images/client/01.jpg",
     list: ["facebook", "instagram", "twitter", "linkedin"],
     name: "Ronny Jofra",
     designation: "C.E.O"
   },
   {
     profile: "assets/images/client/04.jpg",
     list: ["facebook", "instagram", "twitter", "linkedin"],
     name: "Micheal Carlo",
     designation: "Director"
   },
   {
     profile: "assets/images/client/02.jpg",
     list: ["facebook", "instagram", "twitter", "linkedin"],
     name: "Aliana Rosy",
     designation: "Manager"
   },
   {
     profile: "assets/images/client/03.jpg",
     list: ["facebook", "instagram", "twitter", "linkedin"],
     name: "Sofia Razaq",
     designation: "Developer"
   }
 ];

 jobData: IJob[];
 categoryData:ICategory[];
 loader=false;
 constructor(private jobService: JobService,private companyService:CompanyService,private cateService:CategoryService,private empService:EmployeeService) {
  // this.getJobList(false);

  }


 ngOnInit(): void {
   this.getCategoryList();
   this.getLimitJobList(10);
 }

 
  /**
 * to get list of Job data
 * @param useCach
 * @returns void
 */
   getJobList(useCach = false): void {
     this.loader=true;
     this.jobService.getListJob().subscribe(response => {
       this.jobData = response;
       console.log(this.jobData);
       this.loader = false;
     });
   }

   getCategoryList(): void {
    this.loader=true;
    this.cateService.getCategoryWithCOuntList().subscribe(response => {
      this.categoryData = response;
      console.log(this.categoryData);
      this.loader = false;
    });
  }


  getLimitJobList(limit:number)
  {
    this.loader=true;
    this.jobService.getLimitJobList(limit).subscribe(response => {
      this.jobData = response;
      console.log(this.jobData);
      this.loader = false;
    });
  }

  

  getJobListByCategory(id:number): void {
    this.loader=true;
    this.jobService.getJobBycategory(id).subscribe(response => {
      this.jobData = response;
      console.log(this.jobData);
      this.loader = false;
    });
  }


   getCompanyById(id:number):Observable<ICompany>
   {
  return this.companyService.getCompany(id);
   }

   getCategoryById(id:number):Observable<ICategory>
   {
  return this.cateService.getCategory(id);
   }
   getSubCategoryById(id:number):Observable<ISubcategory>
   {
  return this.cateService.getSubCategory(id);
   }

   getEmpById(id:number):Observable<IEmpType>
   {
  return this.empService.getEmployeeType(id);
   }

   getJobTypeId(id:number):Observable<IJobType>
   {
  return this.jobService.getJobTypes(id);
   }
 /**
  * Testimonial Slider
  */
 customOptions: OwlOptions = {
   loop: true,
   mouseDrag: true,
   touchDrag: false,
   pullDrag: false,
   autoplay: true,
   navSpeed: 700,
   navText: ['', ''],
   responsive: {
     0: {
       items: 1
     },
     600: {
       items: 2
     },
     900: {
       items: 3
     }
   },
   nav: false
 };
 

}
