<!-- Hero Start -->
<section class="bg-half d-table w-100" id="home" style="background: url('assets/images/banner/about.jpg');">
  <div class="bg-overlay"></div>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-12 text-center">
        <div class="page-next-level title-heading">
          <h1 class="text-white title-dark"> About us </h1>
          <p class="text-white-50 para-desc mb-0 mx-auto">We deliver the  imission critical IT services that move the world splended bussiness.</p>
          <div class="page-next">
            <nav aria-label="breadcrumb" class="d-inline-block">
              <ul class="breadcrumb bg-white rounded shadow mb-0">
                <li class="breadcrumb-item"><a routerLink="/index">Octohub</a></li>
                <li class="breadcrumb-item active" aria-current="page">Aboutus</li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<div class="position-relative">
  <div class="shape overflow-hidden text-white">
    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
    </svg>
  </div>
</div>
<!-- Hero End -->

<section class="section">
  <div class="container">
    <div class="row align-items-center" id="counter">
      <div class="col-md-6">
        <img src="assets/images/company/about2.png" class="img-fluid" alt="Octohub">
      </div>
      <!--end col-->

      <div class="col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0">
        <div class="ms-lg-4">
          <div class="d-flex mb-4">
            <span class="text-primary h1 mb-0"><span class="counter-value display-1 fw-bold" [CountTo]="15" [from]="0"
                [duration]="4"></span>+</span>
            <span class="h6 align-self-end ms-2">Years <br> Experience</span>
          </div>
          <div class="section-title">
            <h4 class="title mb-4">Who we are ?</h4>
            <p class="text-muted"> Headquartered in USA and with its Offshore Delivery Centre in Hyderabad, India, Octohub is a Specialist Software Consulting company focusing on Outsourced Product Development (OPD), Enterprise Application Development, Digital Transformation, Big Data AI/ML and Technology/Package Implementation services. Our offerings include technology assessment, architecture, design, development, testing, deployment and ongoing support. As part of our OPD service, we adopt an on-demand approach to technology delivery, enabling your business to stay nimble and adaptive. Over past two decades, Octohub has pioneered the use of Enterprise Open Source Software to deliver robust and scalable solutions that result in quick product roll outs and high ROI’s. We have built expertise on Liferay technologies over the past 10 years.
              We contributed significantly to Open Source community development as well. We had built an open source plug-in called Elasticray (https://github.com/Octohub/elasticray) to make Elasticsearch searches from Liferay more efficient. This plugin is made available at Liferay marketplace (https://web.liferay.com/web/pages-rknowsys-technologies/profile) and has more than 500+ downloads from customers with excellent review.</p>

              <p class="text-muted">
                Octohub  facilitates Software Consulting company focusing on Outsourced Product Development (OPD), Enterprise Application Development, Digital Transformation, Big Data AI/ML and Technology/Package Implementation services services to customers from 50+ countries and in 95+ cities around the world. Our 2400+ client managers and 1000+ technical product and service specialists, engage with our clients to choose and integrate the right products in the most efficient way, creating and managing hybrid and secure infrastructures. Hand-in-hand with our clients, our global support and maintenance teams manage and support the infrastructure needed to underpin world-class digital transformation, cloud-based services.
              </p>
            <a routerLink="/contact" class="btn btn-primary mt-3">Contact us</a>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->

  <div class="container mt-5">
    <app-clients-logo></app-clients-logo>
  </div>
  <!--end container-->
</section>
<!--end section-->


<!-- Start -->
<section class="section bg-light pt-0">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12 text-center">
        <div class="video-solution-cta position-relative" style="z-index: 1;">
          <div class="position-relative">
            <img src="assets/images/cta-bg.jpg" class="img-fluid rounded-md shadow-lg" alt="">
            <div class="play-icon">
              <a (click)="openWindowCustomClass(content)" href="javascript:void(0);" class="play-btn video-play-icon">
                <i class="mdi mdi-play text-primary rounded-circle bg-white shadow-lg"></i>
              </a>
              <ng-template #content let-modal>
                <div class="modal-header">
                  <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <youtube-player [videoId]="'yba7hPeTSjk'" height="450" width="780">
                  </youtube-player>
                </div>
              </ng-template>
            </div>
          </div>
          <div class="content mt-md-4 pt-md-2">
            <div class="row justify-content-center">
              <div class="col-lg-10 text-center">
                <div class="row align-items-center">
                  <div class="col-md-12 mt-4 pt-2">
                    <div class="section-title text-md-start">
                      <h6 class="text-white-50">Team</h6>
                      <h4 class="title text-white title-dark mb-0">Meet Experience <br> Team Member</h4>
                    </div>
                  </div>

                  <!--<div class="col-md-6 col-12 mt-4 pt-md-2">
                    <div class="section-title text-md-start">
                      <p class="text-white-50 para-desc">Start working with Landrick that can provide everything you
                        need to generate awareness, drive traffic, connect.</p>
                      <a href="javascript:void(0)" class="text-white title-dark">Read More
                        <i-feather name="arrow-right" class="fea icon-sm"></i-feather>
                      </a>
                    </div>
                  </div>-->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row -->
    <div class="feature-posts-placeholder bg-primary"></div>
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- End -->
 <!-- Shape Start -->
 <div class="position-relative">
  <div class="shape overflow-hidden ">
    <svg viewBox="0 0 2880 48" fill="none" style="color:#d3d3d3 ;" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
    </svg>
  </div>
</div>
<!--Shape End-->
<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
  <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->