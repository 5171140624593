import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import ICategory, { ISubcategory } from '../Interfaces/category.interface';
import { ICompany } from '../Interfaces/company.interface';
import { IEmpType } from '../Interfaces/employee.interface';
import { IJob, IJobType } from '../Interfaces/job.interface';
import { CategoryService } from '../services/category.service';
import { CompanyService } from '../services/company.service';
import { EmployeeService } from '../services/employee.service';
import { JobService } from '../services/job.service';
import { SalaryService } from '../services/salary.service';

@Component({
  selector: 'app-job-details',
  templateUrl: './job-details.component.html',
  styleUrls: ['./job-details.component.css']
})
export class JobDetailsComponent implements OnInit {

  subcategoryData: ISubcategory[];
  jobData: IJob;
  qauliName:string;
  EmpTypename:string;
  jobTypename:string;
  salName:string;
  categoryName:string;

  loader = false;
  constructor(private jobService: JobService,private salaryService:SalaryService,private companyService:CompanyService,private cateService:CategoryService,private empService:EmployeeService, private activatedRoute: ActivatedRoute) {

  }
  ngOnInit(): void {
    this.activatedRoute.params.subscribe(params => {
      if (params.id) {
        this.getJobDetailById();
     
      }
    });
  }
  /**
  * @returns void
  */
  getJobDetailById(): void {
    this.jobService.getJob(parseInt(this.activatedRoute.snapshot.paramMap.get('id'))).subscribe(subcate => {
      this.jobData = subcate;
      console.log(subcate);
      this.getCategoryById(subcate.category_Id);
      this.getEmpById(subcate.emp_Type_Id);
      this.getJobTypeId(subcate.job_Type_Id);
      this.getSalaryCurrency(subcate.salary_Currency);
      this.getJobQualification(subcate.job_Qualification_Id);
    }, error => {
      console.log(error);

    });
  }



  getCompanyById(id:number):Observable<ICompany>
  {
 return this.companyService.getCompany(id);
  }

  getCategoryById(id:number)
  {
 return this.cateService.getCategory(id).subscribe(x=>{
   this.categoryName=x.title;
   console.log(x.title);

   console.log(this.categoryName);
 });
  }
  getSubCategoryById(id:number):Observable<ISubcategory>
  {
 return this.cateService.getSubCategory(id);
  }

  getJobQualification(id:number)
  {
    this.jobService.getJobQual(id).subscribe(c=>{
      this.qauliName=c.title
    });
  }
  getSalaryCurrency(id:number)
  { 
this.salaryService.getSalaryCurrency(id).subscribe(x=>{
this.salName=x.name
});
  }


  getEmpById(id:number)
  {
  this.empService.getEmployeeType(id).subscribe(x=>{
   this.EmpTypename=x.title
 });
  }

  getJobTypeId(id:number)
  {
 this.jobService.getJobTypes(id).subscribe(x=>{
   this.jobTypename=x.title
 });
  }
  
    


}
