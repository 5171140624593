<!-- Hero Start -->
<section class="bg-half d-table w-100" id="home" style="background: url('assets/images/banner/bs3.jpg');">
    <div class="bg-overlay"></div>
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-12 text-center">
          <div class="page-next-level title-heading">
            <h1 class="text-white title-dark"> Digital business transformation </h1>
            <p class="text-white-50 para-desc mb-0 mx-auto">We are a global service-provider company that helps businesses and the public sector to efficiently undergo digital transformation. Our work is to convert digital technologies into profit for our customers and into prosperity of citizens.</p>
            <div class="page-next">
              <nav aria-label="breadcrumb" class="d-inline-block">
                <ul class="breadcrumb bg-white rounded shadow mb-0">
                  <li class="breadcrumb-item"><a routerLink="/index">Octohub</a></li>
                  <li class="breadcrumb-item"><a href="javascript:void(0)">Services</a></li>

                  <li class="breadcrumb-item active" aria-current="page">Digital business transformation</li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
        <!--end col-->
      </div>
      <!--end row-->
    </div>
    <!--end container-->
  </section>
  <!--end section-->
  <div class="position-relative">
    <div class="shape overflow-hidden text-white">
      <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
      </svg>
    </div>
  </div>
  <!-- Hero End -->
  
  <section class="section">
    <div class="container">
      <div class="row align-items-center" id="counter">
       
        <!--end col-->
  
        <div class="col-md-12 mt-4 pt-2 mt-sm-0 pt-sm-0">
          <div class="ms-lg-4">
            
            <div class="section-title text-center">
              <h4 class="title mb-4">Digital business transformation </h4>
              <p class="text-muted">A majority of decisions your customers make today are driven by their digital experiences - be it buying a golf kit online or choosing an orthopaedic expert. CognitivZen, helps in creating a strong market advantage to the customer by designing experiences that ensure positive enhancing experiences for the end users.</p>
  
                <p class="text-muted">
                    We truly believe that technology has an amazing power to bring people together and transcend borders. This is the reason why our global business and operational network already extends to 50 countries and 95 cities worldwide.                </p>
                <p class="text-muted">
                    Digital transformation is inevitable, and it is happening without your active involvement. It is an essential part of your business today, moving from your desktop to your smart phone and to several other devices. This requires harmonizing data from several disparate sources and presenting it in an easy-to consume fashion.                 </p>

                  


            </div>
          </div>
        </div>
        <!--end col-->
      </div>
      <!--end row-->
    </div>
    <!--end container-->
  
    <!--end container-->
  </section>
  

 <!-- Shape Start -->
 <div class="position-relative">
  <div class="shape overflow-hidden ">
    <svg viewBox="0 0 2880 48" fill="none" style="color:#d3d3d3 ;" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
    </svg>
  </div>
</div>
<!--Shape End-->
  <!--end section-->
  <!-- End -->
  
  <!-- Back to top -->
  <a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
    <i-feather name="arrow-up" class="icons"></i-feather>
  </a>
  <!-- Back to top -->