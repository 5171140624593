import { Component, OnInit } from '@angular/core';

interface client {
  image: string;
};

@Component({
  selector: 'app-clients-logo',
  templateUrl: './clients-logo.component.html',
  styleUrls: ['./clients-logo.component.css']
})
export class ClientsLogoComponent implements OnInit {

  /**
   * Clients Logo
   */
   clients_logo: client[] = [
    {
      image: "assets/images/client/f1.png"
    },
    {
      image: "assets/images/client/f2.png"
    },
    {
      image: "assets/images/client/f3.png"
    },
    {
      image: "assets/images/client/f4.png"
    },
    {
      image: "assets/images/client/f1.png"
    }
  ];

  constructor() { }

  ngOnInit(): void {
  }

}
