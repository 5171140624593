import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

interface member {
  profile: string,
  list: string[],
  name: string,
  designation: string,
};

@Component({
  selector: 'app-page-aboutus',
  templateUrl: './page-aboutus.component.html',
  styleUrls: ['./page-aboutus.component.css']
})

/**
 * Aboutus Component
 */
export class PageAboutusComponent implements OnInit {

  /**
   * Member Data
   */
  memberData: member[] = [
    {
      profile: "assets/images/real/BalReddy.jpg",
      list: ['facebook', "instagram", "twitter", "linkedin"],
      name: "Singotam Bal reddy",
      designation: "Founder"
    },
    {
      profile: "assets/images/real/Director.jpeg",
      list: ["facebook", "instagram", "twitter", "linkedin"],
      name: "Singotam Bhaskar reddy",
      designation: "Director"
    },
    {
      profile: "assets/images/real/devamma.JPG",
      list: ["facebook", "instagram", "twitter", "linkedin"],
      name: "Byreddy Rohini Devi",
      designation: "Managing Partner"
    },
    {
      profile: "assets/images/real/vishal reddy.jpg",
      list: ["facebook", "instagram", "twitter", "linkedin"],
      name: "Byreddy Vishal reddy",
      designation: "Managing Director"
    },
    
  ];

  constructor(private modalService: NgbModal) { }

  ngOnInit(): void {
  }

  /**
  * Open modal for show the video
  * @param content content of modal
  */
  openWindowCustomClass(content) {
    this.modalService.open(content, { windowClass: 'dark-modal', size: 'lg', centered: true });
  }

}
