<!-- Hero Start -->
<section class="bg-half d-table w-100" id="home" style="background: url('assets/images/banner/contact.jpg');">
    <div class="bg-overlay"></div>
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-12 text-center">
          <div class="page-next-level title-heading">
            <h1 class="text-white title-dark"> Contact us</h1>
            <p class="text-white-50 para-desc mb-0 mx-auto">Delivering the Right solution 
                .</p>
            <div class="page-next">
              <nav aria-label="breadcrumb" class="d-inline-block">
                <ul class="breadcrumb bg-white rounded shadow mb-0">
                  <li class="breadcrumb-item"><a routerLink="/index">Octohub</a></li>

                  <li class="breadcrumb-item active" aria-current="page">Contact Us</li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
        <!--end col-->
      </div>
      <!--end row-->
    </div>
    <!--end container-->
  </section>
  <!--end section-->
  <div class="position-relative">
    <div class="shape overflow-hidden text-white">
      <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
      </svg>
    </div>
  </div>
  <!-- Hero End -->
  
  <section class="section">
    <div class="container">
      <div class="row align-items-center" id="counter">
       
        <!--end col-->
  
        <div class="col-md-12 mt-4 pt-2 mt-sm-0 pt-sm-0">
          <div class="ms-lg-4">
            
            <div class="section-title text-center">
              <h4 class="title mb-4">Software Outsourcing Services</h4>
              <p class="text-muted"> 
                We are an IT services company using the power of technology and prove   Product Engineering, Enterprise web Mobile App Development, Digital services, Big Data AI ML Tech Implementation providers in the world is an important part of the IT services. We build better futures for our customers, colleagues,  and communities

            </p>
  


            </div>
          </div>
        </div>
        <!--end col-->
      </div>
      <!--end row-->
    </div>
    <!--end container-->
    <div class="container mt-5">
        <app-clients-logo></app-clients-logo>
      </div>
    <!--end container-->
  </section>
  <!--end section-->
  
 
  

  <!--end section-->
  <!-- End -->
  
  <!-- Back to top -->
  <a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
    <i-feather name="arrow-up" class="icons"></i-feather>
  </a>
  <!-- Back to top -->