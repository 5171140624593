<!--Start Banner container-->
<div class="container-fluid mt-100 mt-60">
    <div class="bg-primary rounded shadow py-2">
       
        <!-- <div class="embed-responsive embed-responsive-4by3" >
            <iframe [class.thumbnail]=" " [src]="safeSrc" width="100%" height="'400px'" frameborder="0" webkitallowfullscreen mozallowfullscreen
            allowfullscreen></iframe> -->

            <iframe src="https://www.youtube.com/embed/vtYXZDYJtLc?autoplay=1&showinfo=0&loop=1&list=PLPKL0OcZN52DwERv-NOaq6tT5qQITeHh8&rel=0" onload='javascript:(function(o){o.style.height=o.contentWindow.document.body.scrollHeight+"px";}(this));' style="height:600px;width:100%;border:solid 4px #81aae9;overflow:hidden;"></iframe>
            
        </div>
        <div class="container my-md-5">
            <div class="row">
                <div class="col-12 text-center">
                    <div class="section-title">
                     </div>
                </div>
                <!--end col-->
            </div>
            <!--end row-->
        </div>
        <!--end container-->
<!-- End Banner container--> 


<!-- Start -->
<section class="mt-5 pt-4">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-12 mt-4">
                <owl-carousel-o id="customer-testi" [options]="customOptions">
                    <ng-template carouselSlide>
                        <div class="tiny-slide m-2">
                            <a href="/Lakshmi Events" class="customer-testi">
                                <img src="assets/images/real/b1.jpg" class="img-fluid rounded shadow" alt="">
                            </a>
                        </div>
                    </ng-template>
                    <ng-template carouselSlide>
                        <div class="tiny-slide m-2">
                            <a href="/Lakshmi Events" class="customer-testi">
                                <img src="assets/images/real/b2.jpg" class="img-fluid rounded shadow" alt="">
                            </a>
                        </div>
                    </ng-template>
                    <ng-template carouselSlide>
                        <div class="tiny-slide m-2">
                            <a href="/Lakshmi Events" class="customer-testi">
                                <img src="assets/images/real/b3.jpg" class="img-fluid rounded shadow" alt="">
                            </a>
                        </div>
                    </ng-template>
                    <ng-template carouselSlide>
                        <div class="tiny-slide m-2">
                            <a href="/Lakshmi Events" class="customer-testi">
                                <img src="assets/images/real/b4.jpg" class="img-fluid rounded shadow" alt="">
                            </a>
                        </div>
                    </ng-template>
                    <ng-template carouselSlide>
                        <div class="tiny-slide m-2">
                            <a href="/Lakshmi Events" class="customer-testi">
                                <img src="assets/images/real/b5.jpg" class="img-fluid rounded shadow" alt="">
                            </a>
                        </div>
                    </ng-template>
                    <ng-template carouselSlide>
                        <div class="tiny-slide m-2">
                            <a href="/Lakshmi Events" class="customer-testi">
                                <img src="assets/images/real/b6.jpg" class="img-fluid rounded shadow" alt="">
                            </a>
                        </div>
                    </ng-template>
                    <ng-template carouselSlide>
                        <div class="tiny-slide m-2">
                            <a href="/Lakshmi Events" class="customer-testi">
                                <img src="assets/images/real/b7.jpg" class="img-fluid rounded shadow" alt="">
                            </a>
                        </div>
                    </ng-template>
                    <ng-template carouselSlide>
                        <div class="tiny-slide m-2">
                            <a href="/Lakshmi Events" class="customer-testi">
                                <img src="assets/images/real/b8.jpg" class="img-fluid rounded shadow" alt="">
                            </a>
                        </div>
                    </ng-template>
                </owl-carousel-o>
            </div>
            <!--end col-->
        </div>
    </div>
    <!--end container-->
</section>
<!--end section-->
<!-- End -->

<!-- Start Features -->
<section class="py-4 bg-light">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-3 col-md-6 col-12">
                <div class="d-flex features pt-4 pb-4">
                    <div class="icon text-center rounded-circle text-primary me-3 h5 mb-0">
                        
                        <i class="uil uil-analytics text-primary"></i>
                    </div>
                    <div class="flex-1">
                        <h4 class="title">Location</h4>
                        <p class="text-muted para mb-0">Avasa Homes Location Plan .</p>
                    </div>
                </div>
            </div>
            <!--end col-->

            <div class="col-lg-3 col-md-6 col-12">
                <div class="d-flex features pt-4 pb-4">
                    <div class="icon text-center rounded-circle text-primary me-3 h5 mb-0">
                        <i class="uil uil-airplay text-primary"></i>
                    </div>
                    <div class="flex-1">
                        <h4 class="title">Specifications </h4>
                        <p class="text-muted para mb-0">Avasa Homes Specifications.</p>
                    </div>
                </div>
            </div>
            <!--end col-->

            <div class="col-lg-3 col-md-6 col-12">
                <div class="d-flex features pt-4 pb-4">
                    <div class="icon text-center rounded-circle text-primary me-3 h5 mb-0">
                        <i class="uil uil-clapper-board text-primary"></i>
                    </div>
                    <div class="flex-1">
                        <h4 class="title">Special features</h4>
                        <p class="text-muted para mb-0">Avasa Homes Special features </p>
                    </div>
                </div>
            </div>
            <!--end col-->

            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->
</section>
<!--end section-->
<!-- End Features -->
<!-- Start -->

     <!-- Searching Option for real estate remove  -->
    
<!-- Features Start -->
<section class="section bg-light">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-12 text-center">
          <div class="section-title mb-4 pb-2">
            <h4 class="title mb-4">Specifications</h4>
            <p class="text-muted para-desc mb-0 mx-auto"> # Avasa  <span
                class="text-primary fw-bold">Homes</span>create a home for you and your family with love, comfort and beautiful memories with world class amenities.</p>
          </div>
        </div>
        <!--end col-->
      </div>
      <!--end row-->
  
      <div class="row justify-content-center align-items-center">
        <div class="col-lg-8 col-md-8">
          <div class="row mt-4 pt-2">
            <div class="col-md-6 col-12">
              <div class="d-flex features pt-4 pb-4">
                <div class="icon text-center rounded-circle text-primary me-3 mt-2">
                  <i-feather name="monitor" class="fea icon-ex-md text-primary"></i-feather>
                </div>
                <div class="flex-1">
                  <h4 class="title">STRUCTURE</h4>
                  <p class="text-muted para mb-0">RCC Framed structure including footings, columns, beams and slabs designed for Wind 
                    & Seismic Forces.</p>
                </div>
              </div>
            </div>
            <!--end col-->
  
            <div class="col-md-6 col-12">
              <div class="d-flex features pt-4 pb-4">
                <div class="icon text-center rounded-circle text-primary me-3 mt-2">
                  <i-feather name="feather" class="fea icon-ex-md text-primary"></i-feather>
                </div>
                <div class="flex-1">
                  <h4 class="title">SUPER STRUCTURE</h4>
                  <p class="text-muted para mb-0">9” thick external walls & 4½” thick internal walls of red bricks.</p>
                </div>
              </div>
            </div>
            <!--end col-->
  
            <div class="col-md-6 col-12">
              <div class="d-flex features pt-4 pb-4">
                <div class="icon text-center rounded-circle text-primary me-3 mt-2">
                  <i-feather name="eye" class="fea icon-ex-md text-primary"></i-feather>
                </div>
                <div class="flex-1">
                  <h4 class="title">PAINTING </h4>
                  <p class="text-muted para mb-0">Internal walls : Smooth plastered surface treated with putty and painted with emulsion paint of Asian/ ICI 
                    or equivalent.</p>
                    <p class="text-muted para mb-0">External walls : Smooth finished cement plaster and painted with exterior paint.</p>
                </div>
              </div>
            </div>
            <!--end col-->
  
            <div class="col-md-6 col-12">
              <div class="d-flex features pt-4 pb-4">
                <div class="icon text-center rounded-circle text-primary me-3 mt-2">
                  <i-feather name="user-check" class="fea icon-ex-md text-primary"></i-feather>
                </div>
                <div class="flex-1">
                  <h4 class="title">DOORS</h4>
                  <p class="text-muted para mb-0">Maindoor : Best Teakwood door frame with designer shutter and safety lock with polishing.</p>
                  <p class="text-muted para mb-0">Internal Door : Wooden door frames with laminated flush doors.</p>
                </div>
              </div>
            </div>
            <!--end col-->
  
            <div class="col-md-6 col-12">
              <div class="d-flex features pt-4 pb-4">
                <div class="icon text-center rounded-circle text-primary me-3 mt-2">
                  <i-feather name="smartphone" class="fea icon-ex-md text-primary"></i-feather>
                </div>
                <div class="flex-1">
                  <h4 class="title">WINDOWS </h4>
                  <p class="text-muted para mb-0">UPVC windows with sliding glazed glass panels and mosquito mesh. Ventilators with 
                    louvers.</p>
                </div>
              </div>
            </div>
            <!--end col-->
  
            <div class="col-md-6 col-12">
              <div class="d-flex features pt-4 pb-4">
                <div class="icon text-center rounded-circle text-primary me-3 mt-2">
                  <i-feather name="heart" class="fea icon-ex-md text-primary"></i-feather>
                </div>
                <div class="flex-1">
                  <h4 class="title">FLOORING & SKIRTING</h4>
                  <p class="text-muted para mb-0">2”x2 “Vitrified tiles flooring in Hall, Dining and bedrooms.</p>
                </div>
              </div>
            </div>
            <!--end col-->
            <div class="col-md-6 col-12">
                <div class="d-flex features pt-4 pb-4">
                  <div class="icon text-center rounded-circle text-primary me-3 mt-2">
                    <i-feather name="heart" class="fea icon-ex-md text-primary"></i-feather>
                  </div>
                  <div class="flex-1">
                    <h4 class="title">KITCHEN</h4>
                    <p class="text-muted para mb-0">Polished Granite with Steel Sink and up to 3’-0” height dado and Provision for Chimney 
                        and Exhaust fan.</p>
                  </div>
                </div>
              </div>
              <!--end col-->
              <div class="col-md-6 col-12">
                <div class="d-flex features pt-4 pb-4">
                  <div class="icon text-center rounded-circle text-primary me-3 mt-2">
                    <i-feather name="heart" class="fea icon-ex-md text-primary"></i-feather>
                  </div>
                  <div class="flex-1">
                    <h4 class="title">TOILETS</h4>
                    <p class="text-muted para mb-0">Wash Basin with normal water. WC Flush tank of Hind ware/ Cera of equivalent. Hot and 
                        Cold mixer in shower with Provision for geysers and exhaust. Anti-skid ceramic floor tiles in 
                        Toilets, Balconies & Utility. Tiles dado in Toilets and Utilities up to 7’ feet.</p>
                  </div>
                </div>
              </div>
              <!--end col-->
              <div class="col-md-6 col-12">
                <div class="d-flex features pt-4 pb-4">
                  <div class="icon text-center rounded-circle text-primary me-3 mt-2">
                    <i-feather name="heart" class="fea icon-ex-md text-primary"></i-feather>
                  </div>
                  <div class="flex-1">
                    <h4 class="title">ELECTRIFICATION</h4>
                    <p class="text-muted para mb-0">Wiring : Concealed fire-retardant copper wiring of ISI standard makes.</p>
                    <p class="text-muted para mb-0">Switches & Fixtures : Modular switches and sockets of Havells/ Salzar /equivalent make to all power points, power 
                        sockets for geyser in every bathroom, A/C point in every bedroom and provision for washing 
                       machine, refrigerator provision with meter boards with 3 phase power supply.</p>
                  </div>
                </div>
              </div>
              <!--end col-->
              <div class="col-md-6 col-12">
                <div class="d-flex features pt-4 pb-4">
                  <div class="icon text-center rounded-circle text-primary me-3 mt-2">
                    <i-feather name="heart" class="fea icon-ex-md text-primary"></i-feather>
                  </div>
                  <div class="flex-1">
                    <h4 class="title">PLUMBING / SANITARY FITTINGS </h4>
                    <p class="text-muted para mb-0">Floor mounted / wall mounted EWC of Cera / Hind ware /equivalent in every bathroom. 
                        Ashirwad/ Prince/ equivalent CPVC pipeline and external SWR pipelines </p>
                  </div>
                </div>
              </div>
              <!--end col-->
              <div class="col-md-6 col-12">
                <div class="d-flex features pt-4 pb-4">
                  <div class="icon text-center rounded-circle text-primary me-3 mt-2">
                    <i-feather name="heart" class="fea icon-ex-md text-primary"></i-feather>
                  </div>
                  <div class="flex-1">
                    <h4 class="title">LIFTS </h4>
                    <p class="text-muted para mb-0">Passenger & Service Lifts of Kone /Johnson / equivalent make. </p>
                  </div>
                </div>
              </div>
              <!--end col-->
              <div class="col-md-6 col-12">
                <div class="d-flex features pt-4 pb-4">
                  <div class="icon text-center rounded-circle text-primary me-3 mt-2">
                    <i-feather name="heart" class="fea icon-ex-md text-primary"></i-feather>
                  </div>
                  <div class="flex-1">
                    <h4 class="title">COMMUNICATION</h4>
                    <p class="text-muted para mb-0">Provision for TV & Internet. </p>
                  </div>
                </div>
              </div>
          </div>
          <!--end row-->
        </div>
        <!--end col-->
  
        <div class="col-lg-4 col-md-4 col-12 mt-4 pt-2 text-center text-md-end">
          <img src="assets/images/real/Layout.png" class="img-fluid" alt="">
        </div>
        <!--end col-->
      </div>
      <!--end row-->
    </div>
    <!--end container-->
  </section>
  <!--end section-->
  <!-- Feature End -->
  <!-- Showcase Start -->
<section class="section pt-0 bg-light">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-12 text-center">
          <div class="section-title mb-4 pb-2">
            <h4 class="title mb-4">Special Feature </h4>
            <p class="text-muted para-desc mb-0 mx-auto">Avasa  <span
                class="text-primary fw-bold">Homes</span>create a home for you and your family with love, comfort and beautiful memories with world class amenities.</p>
          </div>
        </div>
        <!--end col-->
      </div>
      <!--end row-->
  
      <div class="row align-items-center">
        <div class="col-lg-5 col-md-6 mt-4 pt-2">
          <img src="assets/images/real/FrontView.png" class="img-fluid mx-auto d-block" alt="">
        </div>
        <!--end col-->
  
        <div class="col-lg-7 col-md-6 mt-4 pt-2">
          <div class="section-title ms-lg-5">
            <h4 class="title mb-4">Best <span class="text-primary">Avasa</span> Home For Your Life</h4>
            <p class="text-muted">Avasa Homescreate a home for you and your family with love, comfort and beautiful memories with world class amenities.</p>
            <ul class="list-unstyled text-muted">
              <li class="mb-0"><span class="text-primary h5 me-2"><i
                    class="uil uil-check-circle align-middle"></i></span>100 % Vaastu Compliant</li>
              <li class="mb-0"><span class="text-primary h5 me-2"><i
                    class="uil uil-check-circle align-middle"></i></span>No Common wallsy</li>
              <li class="mb-0"><span class="text-primary h5 me-2"><i
                    class="uil uil-check-circle align-middle"></i></span>Power backup in common areas and 3 points in each flat</li>
            </ul>
            <ul class="list-unstyled text-muted">
                <li class="mb-0"><span class="text-primary h5 me-2"><i
                      class="uil uil-check-circle align-middle"></i></span>Rain water harvesting pits.</li>
                <li class="mb-0"><span class="text-primary h5 me-2"><i
                      class="uil uil-check-circle align-middle"></i></span>24/7 Security system with CCTV cameras</li>
                <li class="mb-0"><span class="text-primary h5 me-2"><i
                      class="uil uil-check-circle align-middle"></i></span>Children play area and green area with landscaping</li>
              </ul>
              <ul class="list-unstyled text-muted">
                <li class="mb-0"><span class="text-primary h5 me-2"><i
                      class="uil uil-check-circle align-middle"></i></span>24 hours water supply for Bore water and regularly operated</li>
                <li class="mb-0"><span class="text-primary h5 me-2"><i
                      class="uil uil-check-circle align-middle"></i></span>Municipal drinking water</li>
                <li class="mb-0"><span class="text-primary h5 me-2"><i
                      class="uil uil-check-circle align-middle"></i></span>Office room and common washrooms.</li>
              </ul>
              <ul class="list-unstyled text-muted">
                <li class="mb-0"><span class="text-primary h5 me-2"><i
                      class="uil uil-check-circle align-middle"></i></span>Excellent cross ventilation & abundant natural light</li>
                <li class="mb-0"><span class="text-primary h5 me-2"><i
                      class="uil uil-check-circle align-middle"></i></span>Car wash provision</li>
                <li class="mb-0"><span class="text-primary h5 me-2"><i
                      class="uil uil-check-circle align-middle"></i></span>Grand entrance lobby</li>
              </ul>
              <ul class="list-unstyled text-muted">
                <li class="mb-0"><span class="text-primary h5 me-2"><i
                      class="uil uil-check-circle align-middle"></i></span>Community hall for social gatherings</li>
                <li class="mb-0"><span class="text-primary h5 me-2"><i
                      class="uil uil-check-circle align-middle"></i></span>Provision for spacious Library</li>
                <li class="mb-0"><span class="text-primary h5 me-2"><i
                      class="uil uil-check-circle align-middle"></i></span>Additional Service Lift.</li>
              </ul>
              <ul class="list-unstyled text-muted">
                <li class="mb-0"><span class="text-primary h5 me-2"><i
                      class="uil uil-check-circle align-middle"></i></span>Additional Service Lift.</li>
                <li class="mb-0"><span class="text-primary h5 me-2"><i
                      class="uil uil-check-circle align-middle"></i></span>Wide parking place for 4 Wheelers and 2 Wheelers.</li>
            
              </ul>
            <a href="javascript:void(0)" class="mt-3 h6 text-primary">Find Out More <i
                class="uil uil-angle-right-b"></i></a>
          </div>
        </div>
        <!--end col-->
      </div>
      <!--end row-->
    </div>
    <!--end container-->
  
    <div class="container mt-100 mt-60">
      <div class="row justify-content-center">
        <div class="col-12 text-center">
          <div class="section-title mb-4 pb-2">
            <h4 class="title mb-4"><span class="text-primary">Avasa</span> Home Features </h4>
            <p class="text-muted para-desc mb-0 mx-auto"># <span
                class="text-primary fw-bold">Avasa</span> Homes A Gated community DTCP Approved Flats project, Wanaparthy,Telangana</p>
          </div>
        </div>
        <!--end col-->
      </div>
      <!--end row-->
  
      <div class="row justify-content-center">
        <div class=" mt-4 pt-2 text-center">
          <ul ngbNav #nav="ngbNav"
            class="nav-pills nav-justified flex-column flex-sm-row rounded col-lg-8 col-md-12 offset-md-2">
            <li ngbNavItem>
              <a ngbNavLink class="rounded">
                <div class="text-center py-2">
                  <h6 class="mb-0">Salient Features</h6>
                </div>
              </a>
              <ng-template ngbNavContent>
                <div class="row align-items-center">
                  <div class="col-md-6">
                    <img src="assets/images/real/FrontView.png" class="img-fluid mx-auto d-block" alt="">
                  </div>
                  <!--end col-->
  
                  <div class="col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
                    <div class="section-title text-md-start">
                      <h4 class="title mb-4"><i class="uil uil-angle-double-right text-primary"></i> AVASA Salient Features</h4>
                      <p class="text-muted">You can combine all the Avasa templates into a single one, you can take a
                        component from the Application theme and use it in the Website.</p>
                      <ul class="list-unstyled text-muted">
                        <li class="mb-0"><span class="text-primary h5 me-2"><i
                              class="uil uil-check-circle align-middle"></i></span>DTCP Approved Project.</li>
                        <li class="mb-0"><span class="text-primary h5 me-2"><i
                              class="uil uil-check-circle align-middle"></i></span>RERA registered Project</li>
                        <li class="mb-0"><span class="text-primary h5 me-2"><i
                              class="uil uil-check-circle align-middle"></i></span>Wide approach roads from all directions.</li>
                      </ul>
                      <a href="javascript:void(0)" class="mt-3 h6 text-primary">Find Out More <i
                          class="uil uil-angle-right-b"></i></a>
                    </div>
                  </div>
                  <!--end col-->
                </div>
                <!--end row-->
              </ng-template>
            </li>
            <li ngbNavItem>
              <a ngbNavLink class="rounded">
                <div class="text-center py-2">
                  <h6 class="mb-0">Prime Location</h6>
                </div>
              </a>
              <ng-template ngbNavContent>
                <div class="row align-items-center">
                  <div class="col-md-6">
                    <img src="assets/images/real/3dFlatView.png" class="img-fluid mx-auto d-block" alt="">
                  </div>
                  <!--end col-->
  
                  <div class="col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
                    <div class="section-title text-md-start">
                      <h4 class="title mb-4"><i class="uil uil-angle-double-right text-primary"></i> Avasa Homes Creative Design and
                        Developments </h4>
                      <p class="text-muted">Avasa Homes A Gated community DTCP Approved Flats project, Wanaparthy,Telangana.</p>
                      <ul class="list-unstyled text-muted">
                        <li class="mb-0"><span class="text-primary h5 me-2"><i
                              class="uil uil-check-circle align-middle"></i></span>Bus stand</li>
                        <li class="mb-0"><span class="text-primary h5 me-2"><i
                              class="uil uil-check-circle align-middle"></i></span>Market & shopping malls</li>
                        <li class="mb-0"><span class="text-primary h5 me-2"><i
                              class="uil uil-check-circle align-middle"></i></span>Reputed Educational Institutions</li>
                        <li class="mb-0"><span class="text-primary h5 me-2"><i
                              class="uil uil-check-circle align-middle"></i></span>Banks & Hospitals.</li>
                        <li class="mb-0"><span class="text-primary h5 me-2"><i
                                class="uil uil-check-circle align-middle"></i></span>Hyderabad Highway- 1.0 KM..</li>               
                      </ul>
                      <a href="javascript:void(0)" class="mt-3 h6 text-primary">Find Out More <i
                          class="uil uil-angle-right-b"></i></a>
                    </div>
                  </div>
                  <!--end col-->

                </div>
                <!--end row-->
              </ng-template>
            </li>
            <li ngbNavItem>
              <a ngbNavLink class="rounded">
                <div class="text-center py-2">
                  <h6 class="mb-0">Walkable distance from</h6>
                </div>
              </a>
              <ng-template ngbNavContent>
                <div class="row align-items-center">
                  <div class="col-md-6">
                    <img src="assets/images/real/ParkingPlan.png" class="img-fluid mx-auto d-block" alt="">
                  </div>
                  <!--end col-->
  
                  <div class="col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
                    <div class="section-title text-md-start">
                      <!-- <h4 class="title mb-4"><i class="uil uil-angle-double-right text-primary"></i> 24/7 Security system</h4> -->
                      <p class="text-muted">Avasa Homes A Gated community DTCP Approved Flats project, Wanaparthy,Telangana</p>
                      <ul class="list-unstyled text-muted">
                        <li class="mb-0"><span class="text-primary h5 me-2"><i
                              class="uil uil-check-circle align-middle"></i></span>Schools & Collages ( Jagruthi Junior College ,Sri CV Raman Jr College ) </li>
                          <li class="mb-0"><span class="text-primary h5 me-2"><i
                            class="uil uil-check-circle align-middle"></i></span>Banks & Hospitals.</li>
                      <li class="mb-0"><span class="text-primary h5 me-2"><i
                              class="uil uil-check-circle align-middle"></i></span>Hyderabad Highway- 1.0 KM.</li> 
                      </ul>
                      <a href="javascript:void(0)" class="mt-3 h6 text-primary">Find Out More <i
                          class="uil uil-angle-right-b"></i></a>
                    </div>
                  </div>
                  <!--end col-->
                </div>
                <!--end row-->
              </ng-template>
            </li>
          </ul>
          <div [ngbNavOutlet]="nav" class="col-12 mt-4 pt-2"></div>
        </div>
      </div>
    </div>
    <!--end container-->
    
  </section>
  
  <!--end section-->
  
  <!-- Showcase End -->

<!--Start Banner container-->
<div class="container-fluid mt-100 mt-60">
    <div class="bg-primary rounded shadow py-2">
       
        <!-- <div class="embed-responsive embed-responsive-4by3" >
            <iframe [class.thumbnail]=" " [src]="safeSrc" width="100%" height="'400px'" frameborder="0" webkitallowfullscreen mozallowfullscreen
            allowfullscreen></iframe> -->

            <iframe src="https://www.youtube.com/embed/M5cyJisOLtw?autoplay=1&showinfo=0&loop=1&list=PLPKL0OcZN52DwERv-NOaq6tT5qQITeHh8&rel=0" onload='javascript:(function(o){o.style.height=o.contentWindow.document.body.scrollHeight+"px";}(this));' style="height:400px;width:100%;border:solid 4px #81aae9;overflow:hidden;"></iframe>
            
        </div>
        <div class="container my-md-5">
            <div class="row">
                <div class="col-12 text-center">
                    <div class="section-title">
                     </div>
                </div>
                <!--end col-->
            </div>
            <!--end row-->
        </div>
        <!--end container-->
<!-- End Banner container-->
    <div class="container mt-100 mt-60">
        <div class="row align-items-center">
            <div class="col-lg-4">
                <div class="section-title text-center text-lg-start mb-4 mb-lg-0">
                    <span class="badge rounded-pill bg-soft-primary">Avasa Homes </span>
                    <h4 class="title mt-3 mb-4">Recent Properties</h4>
                    <p class="text-muted mx-auto mx-lg-star para-desc mb-0"> # <span
                            class="text-primary fw-bold">Fortune Infra Projects Avasa </span> Homes A Gated community DTCP Approved  Flats project,  Wanaparthy,Telangana</p>
                    <div class="mt-4 d-none d-lg-block">
                        <a href="assets/images/real/fortunewanaparthy.pdf" class="btn btn-primary">Download Brochure</a>
                    </div>
                </div>
            </div>
            <!--end col-->

            <div class="col-lg-8 mt-4 mt-lg-0 pt-2 pt-lg-0">
                <owl-carousel-o id="customer-testi" [options]="customOptions">
                    <ng-template carouselSlide>
                        <div class="tiny-slide">
                            <div
                                class="card client-testi shop-list border-0 shadow position-relative overflow-hidden m-2">
                                <div class="shop-image position-relative overflow-hidden shadow">
                                    <a routerLink="/Lakshmi Events"><img src="assets/images/real/b1.jpg"
                                            class="img-fluid" alt=""></a>
                                    <ul class="list-unstyled shop-icons">
                                        <li class="list-inline-item"><a href="javascript:void(0)"
                                                class="btn btn-icon btn-pills btn-primary"><i
                                                    class="uil uil-estate icons"></i></a></li>
                                        <li class="list-inline-item"><a href="javascript:void(0)"
                                                class="btn btn-icon btn-pills btn-primary"><i
                                                    class="uil uil-heart icons"></i></a></li>
                                        <li class="list-inline-item"><a href="javascript:void(0)"
                                                class="btn btn-icon btn-pills btn-primary"><i
                                                    class="uil uil-camera icons"></i></a></li>
                                    </ul>
                                </div>
                                <div class="card-body content p-4">
                                    <a href="javascript:void(0)" class="text-dark product-name h6">Luxury Apartment In
                                        Avasa Homes</a>
                                    <!--<ul class="list-unstyled text-muted mt-2 mb-0">
                                        <li class="list-inline-item me-3"><i class="uil uil-bed me-1"></i>1 Bed</li>
                                        <li class="list-inline-item"><i class="uil uil-bath me-1"></i>1 Bath</li>
                                    </ul> --> 
                                      <ul class="list-unstyled d-flex justify-content-between mt-2 mb-0">
                                         <!-- <li class="list-inline-item"><b>₹ 44000</b></li> --> 
                                        <li class="list-inline-item text-muted"> Start Onwards 1162 sft</li> 
                                    </ul> 
                                </div>
                            </div>
                        </div>
                    </ng-template>
                    <ng-template carouselSlide>
                        <div class="tiny-slide">
                            <div
                                class="card client-testi shop-list border-0 shadow position-relative overflow-hidden m-2">
                                <div class="shop-image position-relative overflow-hidden shadow">
                                    <a routerLink="/Lakshmi Events"><img src="assets/images/real/b2.jpg"
                                            class="img-fluid" alt=""></a>
                                    <ul class="list-unstyled shop-icons">
                                        <li class="list-inline-item"><a href="javascript:void(0)"
                                                class="btn btn-icon btn-pills btn-primary"><i
                                                    class="uil uil-estate icons"></i></a></li>
                                        <li class="list-inline-item"><a href="javascript:void(0)"
                                                class="btn btn-icon btn-pills btn-primary"><i
                                                    class="uil uil-heart icons"></i></a></li>
                                        <li class="list-inline-item"><a href="javascript:void(0)"
                                                class="btn btn-icon btn-pills btn-primary"><i
                                                    class="uil uil-camera icons"></i></a></li>
                                    </ul>
                                </div>
                                <div class="card-body content p-4">
                                    <a href="javascript:void(0)" class="text-dark product-name h6">Luxury Apartment In
                                        Avasa Homes</a>
                                    <!-- <ul class="list-unstyled text-muted mt-2 mb-0">
                                        <li class="list-inline-item me-3"><i class="uil uil-bed me-1"></i>1 Bed</li>
                                        <li class="list-inline-item"><i class="uil uil-bath me-1"></i>1 Bath</li>
                                    </ul> -->
                                    <ul class="list-unstyled d-flex justify-content-between mt-2 mb-0">
                                        <!--<li class="list-inline-item"><b>₹ 44000</b></li> -->
                                        <li class="list-inline-item text-muted"> Start Onwards 1162 sft</li> 
                                    </ul> 
                                </div>
                            </div>
                        </div>
                    </ng-template>
                    <ng-template carouselSlide>
                        <div class="tiny-slide">
                            <div
                                class="card client-testi shop-list border-0 shadow position-relative overflow-hidden m-2">
                                <div class="shop-image position-relative overflow-hidden shadow">
                                    <a routerLink="/Lakshmi Events"><img src="assets/images/real/b3.jpg"
                                            class="img-fluid" alt=""></a>
                                    <ul class="list-unstyled shop-icons">
                                        <li class="list-inline-item"><a href="javascript:void(0)"
                                                class="btn btn-icon btn-pills btn-primary"><i
                                                    class="uil uil-estate icons"></i></a></li>
                                        <li class="list-inline-item"><a href="javascript:void(0)"
                                                class="btn btn-icon btn-pills btn-primary"><i
                                                    class="uil uil-heart icons"></i></a></li>
                                        <li class="list-inline-item"><a href="javascript:void(0)"
                                                class="btn btn-icon btn-pills btn-primary"><i
                                                    class="uil uil-camera icons"></i></a></li>
                                    </ul>
                                </div>
                                <div class="card-body content p-4">
                                    <a href="javascript:void(0)" class="text-dark product-name h6">Luxury Apartment In
                                        Avasa Homes</a>
                                <!--    <ul class="list-unstyled text-muted mt-2 mb-0">
                                        <li class="list-inline-item me-3"><i class="uil uil-bed me-1"></i>1 Bed</li>
                                        <li class="list-inline-item"><i class="uil uil-bath me-1"></i>1 Bath</li>
                                    </ul> -->
                                     <ul class="list-unstyled d-flex justify-content-between mt-2 mb-0">
                                        <!-- <li class="list-inline-item"><b>₹ 44000</b></li> -->
                                        <li class="list-inline-item text-muted">Start Onwards 1162 sft</li> 
                                    </ul> 
                                </div>
                            </div>
                        </div>
                    </ng-template>
                    <ng-template carouselSlide>
                        <div class="tiny-slide">
                            <div
                                class="card client-testi shop-list border-0 shadow position-relative overflow-hidden m-2">
                                <div class="shop-image position-relative overflow-hidden shadow">
                                    <a routerLink="/Lakshmi Events"><img src="assets/images/real/b4.jpg"
                                            class="img-fluid" alt=""></a>
                                    <ul class="list-unstyled shop-icons">
                                        <li class="list-inline-item"><a href="javascript:void(0)"
                                                class="btn btn-icon btn-pills btn-primary"><i
                                                    class="uil uil-estate icons"></i></a></li>
                                        <li class="list-inline-item"><a href="javascript:void(0)"
                                                class="btn btn-icon btn-pills btn-primary"><i
                                                    class="uil uil-heart icons"></i></a></li>
                                        <li class="list-inline-item"><a href="javascript:void(0)"
                                                class="btn btn-icon btn-pills btn-primary"><i
                                                    class="uil uil-camera icons"></i></a></li>
                                    </ul>
                                </div>
                                <div class="card-body content p-4">
                                    <a href="javascript:void(0)" class="text-dark product-name h6">Luxury Apartment In
                                        Avasa Homes</a>
                                    <!-- <ul class="list-unstyled text-muted mt-2 mb-0">
                                        <li class="list-inline-item me-3"><i class="uil uil-bed me-1"></i>1 Bed</li>
                                        <li class="list-inline-item"><i class="uil uil-bath me-1"></i>1 Bath</li>
                                    </ul> -->
                                    <ul class="list-unstyled d-flex justify-content-between mt-2 mb-0">
                                        <!--  <li class="list-inline-item"><b>₹ 44000</b></li> -->
                                        <li class="list-inline-item text-muted">Start Onwards 1162 sft</li> 
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                    <ng-template carouselSlide>
                        <div class="tiny-slide">
                            <div
                                class="card client-testi shop-list border-0 shadow position-relative overflow-hidden m-2">
                                <div class="shop-image position-relative overflow-hidden shadow">
                                    <a routerLink="/Lakshmi Events"><img src="assets/images/real/b5.jpg"
                                            class="img-fluid" alt=""></a>
                                    <ul class="list-unstyled shop-icons">
                                        <li class="list-inline-item"><a href="javascript:void(0)"
                                                class="btn btn-icon btn-pills btn-primary"><i
                                                    class="uil uil-estate icons"></i></a></li>
                                        <li class="list-inline-item"><a href="javascript:void(0)"
                                                class="btn btn-icon btn-pills btn-primary"><i
                                                    class="uil uil-heart icons"></i></a></li>
                                        <li class="list-inline-item"><a href="javascript:void(0)"
                                                class="btn btn-icon btn-pills btn-primary"><i
                                                    class="uil uil-camera icons"></i></a></li>
                                    </ul>
                                </div>
                                <div class="card-body content p-4">
                                    <a href="javascript:void(0)" class="text-dark product-name h6">Luxury Apartment In
                                        Avasa Homes</a>
                                   <!-- <ul class="list-unstyled text-muted mt-2 mb-0">
                                        <li class="list-inline-item me-3"><i class="uil uil-bed me-1"></i>1 Bed</li>
                                        <li class="list-inline-item"><i class="uil uil-bath me-1"></i>1 Bath</li>
                                    </ul> -->
                                      <ul class="list-unstyled d-flex justify-content-between mt-2 mb-0">
                                       <!-- <li class="list-inline-item"><b>₹ 44000</b></li> -->
                                        <li class="list-inline-item text-muted"> Start Onwards 1162 sft</li>
                                    </ul> 
                                </div>
                            </div>
                        </div>
                    </ng-template>
                    <ng-template carouselSlide>
                        <div class="tiny-slide">
                            <div
                                class="card client-testi shop-list border-0 shadow position-relative overflow-hidden m-2">
                                <div class="shop-image position-relative overflow-hidden shadow">
                                    <a routerLink="/Lakshmi Events"><img src="assets/images/real/b6.jpg"
                                            class="img-fluid" alt=""></a>
                                    <ul class="list-unstyled shop-icons">
                                        <li class="list-inline-item"><a href="javascript:void(0)"
                                                class="btn btn-icon btn-pills btn-primary"><i
                                                    class="uil uil-estate icons"></i></a></li>
                                        <li class="list-inline-item"><a href="javascript:void(0)"
                                                class="btn btn-icon btn-pills btn-primary"><i
                                                    class="uil uil-heart icons"></i></a></li>
                                        <li class="list-inline-item"><a href="javascript:void(0)"
                                                class="btn btn-icon btn-pills btn-primary"><i
                                                    class="uil uil-camera icons"></i></a></li>
                                    </ul>
                                </div>
                                <div class="card-body content p-4">
                                    <a href="javascript:void(0)" class="text-dark product-name h6">Luxury Apartment In
                                        Avasa Homes</a>
                                    <!--<ul class="list-unstyled text-muted mt-2 mb-0">
                                        <li class="list-inline-item me-3"><i class="uil uil-bed me-1"></i>1 Bed</li>
                                        <li class="list-inline-item"><i class="uil uil-bath me-1"></i>1 Bath</li>
                                    </ul>  -->
                                    <ul class="list-unstyled d-flex justify-content-between mt-2 mb-0">
                                         <!-- <li class="list-inline-item"><b>₹ 44000</b></li>  -->
                                        <li class="list-inline-item text-muted">Start Onwards 1162 sft</li> 
                                    </ul> 
                                </div>
                            </div>
                        </div>
                    </ng-template>
                    <ng-template carouselSlide>
                        <div class="tiny-slide">
                            <div
                                class="card client-testi shop-list border-0 shadow position-relative overflow-hidden m-2">
                                <div class="shop-image position-relative overflow-hidden shadow">
                                    <a routerLink="/Lakshmi Events"><img src="assets/images/real/b7.jpg"
                                            class="img-fluid" alt=""></a>
                                    <ul class="list-unstyled shop-icons">
                                        <li class="list-inline-item"><a href="javascript:void(0)"
                                                class="btn btn-icon btn-pills btn-primary"><i
                                                    class="uil uil-estate icons"></i></a></li>
                                        <li class="list-inline-item"><a href="javascript:void(0)"
                                                class="btn btn-icon btn-pills btn-primary"><i
                                                    class="uil uil-heart icons"></i></a></li>
                                        <li class="list-inline-item"><a href="javascript:void(0)"
                                                class="btn btn-icon btn-pills btn-primary"><i
                                                    class="uil uil-camera icons"></i></a></li>
                                    </ul>
                                </div>
                                <div class="card-body content p-4">
                                    <a href="javascript:void(0)" class="text-dark product-name h6">Luxury Apartment In
                                        Avasa Homes</a>
                                    <!--  <ul class="list-unstyled text-muted mt-2 mb-0">
                                        <li class="list-inline-item me-3"><i class="uil uil-bed me-1"></i>1 Bed</li>
                                        <li class="list-inline-item"><i class="uil uil-bath me-1"></i>1 Bath</li>
                                    </ul> -->
                                    <ul class="list-unstyled d-flex justify-content-between mt-2 mb-0">
                                        <!--  <li class="list-inline-item"><b>₹ 44000</b></li> -->
                                        <li class="list-inline-item text-muted"> Start Onwards 1162 sft</li> 
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                    <ng-template carouselSlide>
                        <div class="tiny-slide">
                            <div
                                class="card client-testi shop-list border-0 shadow position-relative overflow-hidden m-2">
                                <div class="shop-image position-relative overflow-hidden shadow">
                                    <a routerLink="/Lakshmi Events"><img src="assets/images/real/b8.jpg"
                                            class="img-fluid" alt=""></a>
                                    <ul class="list-unstyled shop-icons">
                                        <li class="list-inline-item"><a href="javascript:void(0)"
                                                class="btn btn-icon btn-pills btn-primary"><i
                                                    class="uil uil-estate icons"></i></a></li>
                                        <li class="list-inline-item"><a href="javascript:void(0)"
                                                class="btn btn-icon btn-pills btn-primary"><i
                                                    class="uil uil-heart icons"></i></a></li>
                                        <li class="list-inline-item"><a href="javascript:void(0)"
                                                class="btn btn-icon btn-pills btn-primary"><i
                                                    class="uil uil-camera icons"></i></a></li>
                                    </ul>
                                </div>
                                <div class="card-body content p-4">
                                    <a href="javascript:void(0)" class="text-dark product-name h6">Luxury Apartment In
                                        Avasa Homes</a>
                                    <!-- <ul class="list-unstyled text-muted mt-2 mb-0">
                                        <li class="list-inline-item me-3"><i class="uil uil-bed me-1"></i>1 Bed</li>
                                        <li class="list-inline-item"><i class="uil uil-bath me-1"></i>1 Bath</li>
                                    </ul> -->
                                     <ul class="list-unstyled d-flex justify-content-between mt-2 mb-0">
                                        <!-- <li class="list-inline-item"><b>₹ 44000</b></li> -->
                                        <li class="list-inline-item text-muted">Start Onwards 1162 sft</li> 
                                    </ul> 
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </owl-carousel-o>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->

    <div class="container-fluid mt-100 mt-60">
        <div class="rounded shadow py-5" style="background: url('assets/images/real/Banner1.jpg') center center;">
            <div class="container">
                <div class="row">
                    <div class="col-lg-4 col-md-6">
                        <div class="rounded p-4 bg-light">
                           <app-review-testmonial [reviewData]="reviewData"></app-review-testmonial> 
                        </div>
                    </div>
                    <!--end col-->
                </div>
                <!--end row-->
            </div>
            <!--end container-->
        </div>
    </div>
    <!--end container fluid-->

    
    <!--end container-->

<!--end section-->
<!-- End -->

<!-- News Start -->
<section class="section pt-0" style="background: url('assets/images/real/build.png') bottom no-repeat;">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12 text-center">
                <div class="section-title mb-4 pb-2">
                    <h4 class="title mb-4">Latest Blog & News</h4>
                    <p class="text-muted para-desc mx-auto mb-0"># <span
                            class="text-primary fw-bold">Avasa</span>Homes create a home
                            for you and your family with love, comfort and beautiful memories.</p>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->

        <app-blog [blogData]="blogData"></app-blog>
    </div>
    <!--end container-->
</section>
<!--end section-->
<!-- Blog End -->

<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
    <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->