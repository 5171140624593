import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IJob } from '../Interfaces/job.interface';
import { CategoryService } from '../services/category.service';
import { CompanyService } from '../services/company.service';
import { EmployeeService } from '../services/employee.service';
import { JobService } from '../services/job.service';


interface jobSideBar {
  image: string,
  message: string,
  designation: string,
  icon: string,
  name: string,
  location: string,
  list: string[],
};
@Component({
  selector: 'app-all-jobs',
  templateUrl: './all-jobs.component.html',
  styleUrls: ['./all-jobs.component.css']
})
export class AllJobsComponent implements OnInit {

  jobData: IJob[];
  qauliName:string;
  EmpTypename:string;
  jobTypename:string;
  salName:string;
  categoryName:string;

  loader = false;
  constructor(private jobService: JobService,private companyService:CompanyService,private cateService:CategoryService,private empService:EmployeeService, private activatedRoute: ActivatedRoute) {

  }
  ngOnInit(): void {
    this.activatedRoute.params.subscribe(params => {
      if (params.id) {
        this.getJobListByCateId();
     
      }
      else
      {
        this.getJobList();
      }
    });
  }
   jobsSideBarsData: jobSideBar[] = [
    {
      image: "assets/images/job/full.jpg",
      designation: "Web Designer",
      message: "Full Time",
      icon: "assets/images/job/Circleci.svg",
      name: "CircleCi",
      location: "San Francisco",
      list: ['2 Year Expirence', "Working Hours- 6hr", "Information strategy"]
    },
    {
      image: "assets/images/job/full.jpg",
      designation: "Web Developer",
      message: "Full Time",
      icon: "assets/images/job/Codepen.svg",
      name: "Codepen",
      location: "San Francisco",
      list: ['2 Year Expirence', "Working Hours- 6hr", "Information strategy"]
    },
    {
      image: "assets/images/job/part.jpg",
      designation: "UX Designer",
      message: "Part Time",
      icon: "assets/images/job/Discord.svg",
      name: "Discord",
      location: "San Francisco",
      list: ['2 Year Expirence', "Working Hours- 6hr", "Information strategy"]
    },
    {
      image: "assets/images/job/full.jpg",
      designation: "UI Designer",
      message: "Full Time",
      icon: "assets/images/job/Eslint.svg",
      name: "Eslint",
      location: "San Francisco",
      list: ['2 Year Expirence', "Working Hours- 6hr", "Information strategy"]
    },
    {
      image: "assets/images/job/remote.jpg",
      designation: "Back-End Developer",
      message: "Remote",
      icon: "assets/images/job/Gitlab.svg",
      name: "Gitlab",
      location: "San Francisco",
      list: ['2 Year Expirence', "Working Hours- 6hr", "Information strategy"]
    },
    {
      image: "assets/images/job/part.jpg",
      designation: "Photographer",
      message: "Full Time",
      icon: "assets/images/job/Gradle.svg",
      name: "Gradle",
      location: "San Francisco",
      list: ['2 Year Expirence', "Working Hours- 6hr", "Information strategy"]
    },
    {
      image: "assets/images/job/full.jpg",
      designation: "Mechanic",
      message: "Full Time",
      icon: "assets/images/job/Mg.svg",
      name: "Mg",
      location: "San Francisco",
      list: ['2 Year Expirence', "Working Hours- 6hr", "Information strategy"]
    },
    {
      image: "assets/images/job/part.jpg",
      designation: "Frontend Developer",
      message: "Part Time",
      icon: "assets/images/job/Sketch.svg",
      name: "Sketch",
      location: "San Francisco",
      list: ['2 Year Expirence', "Working Hours- 6hr", "Information strategy"]
    },
    {
      image: "assets/images/job/remote.jpg",
      designation: "Web Designer",
      message: "Remote",
      icon: "assets/images/job/Twilio.svg",
      name: "Twilio",
      location: "San Francisco",
      list: ['2 Year Expirence', "Working Hours- 6hr", "Information strategy"]
    },
    {
      image: "assets/images/job/part.jpg",
      designation: "Php Developer",
      message: "Part Time",
      icon: "assets/images/job/Udemy.svg",
      name: "Udemy",
      location: "San Francisco",
      list: ['2 Year Expirence', "Working Hours- 6hr", "Information strategy"]
    },
    {
      image: "assets/images/job/full.jpg",
      designation: "Web Designer",
      message: "Full Time",
      icon: "assets/images/job/Webhooks.svg",
      name: "Webhooks",
      location: "San Francisco",
      list: ['2 Year Expirence', "Working Hours- 6hr", "Information strategy"]
    },
    {
      image: "assets/images/job/remote.jpg",
      designation: "Python Developer",
      message: "Remote",
      icon: "assets/images/job/Circleci.svg",
      name: "Circleci",
      location: "San Francisco",
      list: ['2 Year Expirence', "Working Hours- 6hr", "Information strategy"]
    }
  ];
  getJobListByCateId(): void {
    this.jobService.getJobBycategory(parseInt(this.activatedRoute.snapshot.paramMap.get('id'))).subscribe(subcate => {
      this.jobData = subcate;
      console.log(subcate);
     
    }, error => {
      console.log(error);

    });
  }

  getJobTypeId(id:number)
  {
 this.jobService.getJobTypes(id).subscribe(x=>{
 return x.title
 });
}

getCompanyById(id:number)
{
 this.companyService.getCompany(id).subscribe(x=>{
  return x.company_name
});
}
  getJobList(): void {
    this.jobService.getListJob().subscribe(subcate => {
      this.jobData = subcate;
      console.log(subcate);
     
    }, error => {
      console.log(error);

    });
  }
}
