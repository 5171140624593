import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ICompany } from '../Interfaces/company.interface';
//import { IPagination } from './pagination.interface';

const baseUrl = environment.apiUrl + 'company/';


@Injectable({
  providedIn: 'root'
})
export class CompanyService {


  constructor(private http: HttpClient) { }

 
   
  /**
* to get emp by id
* @param id the id number for emp 
* @returns Iemp observable  
*/
  getCompany(id: number): Observable<ICompany> {
   
    return this.http.get<ICompany>(baseUrl + id);
  }


  getList(): Observable<ICompany[]> 
  {
    return this.http.get<ICompany[]>(baseUrl);
  }

  /**
 * to create new ICompany to the backend 
 * @param data as a category interface
 * @returns ICompany object observable
  */
  create(data: ICompany): Observable<ICompany> {
    return this.http.post<ICompany>(baseUrl, data);
  }

  


  /**
   * @param id IEmpType id to be updated
   * @param data as a category interface
   * @returns IEmpType object observable
   */
  update(id: number, data: ICompany): Observable<ICompany> {
    console.log(id);
    return this.http.patch<ICompany>(baseUrl + id, data);
  }

  

  /**
   * to search in CATEGORY 
   * @param searchText CATEGORY id to be find CATEGORY 
   * @returns CATEGORY object observable
   */
  getFind(searchText: string): Observable<ICompany[]> {

    return this.http.get<ICompany[]>(baseUrl + 'find?search=' + searchText);
  }




  /**
  * Soft Delete IEmpType based on IEmpType ID in parameter
  * @param id IEmpType id to deleted
  * @returns IEmpType object observable
  */
  delete(id: number): Observable<ICompany> {
    return this.http.delete<ICompany>(baseUrl + id);
  }


}


