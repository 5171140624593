<!-- Hero Start -->
<section class="bg-half d-table w-100" id="home" style="background: url('assets/images/banner/bs5.jpg');">
    <div class="bg-overlay"></div>
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-12 text-center">
          <div class="page-next-level title-heading">
            <h1 class="text-white title-dark">Technology Implementation </h1>
            <p class="text-white-50 para-desc mb-0 mx-auto"> designing  and development innovative and professional software solution for the hundred  of companies across global tech makes almost two decade now.</p>
            <div class="page-next">
              <nav aria-label="breadcrumb" class="d-inline-block">
                <ul class="breadcrumb bg-white rounded shadow mb-0">
                  <li class="breadcrumb-item"><a routerLink="/index">Octohub</a></li>
                  <li class="breadcrumb-item"><a href="javascript:void(0)">Services</a></li>

                  <li class="breadcrumb-item active" aria-current="page">Technology Implementation</li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
        <!--end col-->
      </div>
      <!--end row-->
    </div>
    <!--end container-->
  </section>
  <!--end section-->
  <div class="position-relative">
    <div class="shape overflow-hidden text-white">
      <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
      </svg>
    </div>
  </div>
  <!-- Hero End -->
  
  <section class="section">
    <div class="container">
      <div class="row align-items-center" id="counter">
       
        <!--end col-->
  
        <div class="col-md-12 mt-4 pt-2 mt-sm-0 pt-sm-0">
          <div class="ms-lg-4">
            
            <div class="section-title text-center">
              <h4 class="title mb-4">Technology Implementation </h4>
              <p class="text-muted" style="text-align: left;">We can provide you with technology professionals required to help implement packaged software solutions - project managers, analysts, technical architects, software developers, and quality assurance analysts - to ensure successful implementation of a software solution.</p>
  
                <p class="text-muted" style="text-align: left;">
                    Octohub has supported clients to map and integrate in-house applications to new releases of software and have proven our ability to deliver on time, to the quality and cost. With our advanced project management and integration methodologies, we make sure that the delivery of the project is successful.                </p>
                <p class="text-muted" style="text-align: left;">                
                    <span style="font-weight: 900;">Our specific services include: </span>
                    <br>
                    1.Project Oversight and Program Management

                    <br>
                    2.Business Analysis, Gap Analysis, Functional Specifications

                    <br>
                    3.Package/Vendor Selection<br>
                    4.End-to-End Implementation
<br>
5.Technical/Functional Resource Augmentation

<br>
6.Maintenance, Enhancements, Version Upgrades
<br>
7.Data Cleaning and Transformation
<br>
8.Change Management

                </p>

                <p class="text-muted" style="text-align: left;">                
                    Our expertise in Business Analytics, Business Intelligence (BI), and Visualization can help you derive deeper actionable insights on your customers’ behaviour so you can optimise your business offerings according to the touchpoints.                </p>


            </div>
          </div>
        </div>
        <!--end col-->
      </div>
      <!--end row-->
    </div>
    <!--end container-->
  
    <!--end container-->
  </section>
  

 <!-- Shape Start -->
 <div class="position-relative">
  <div class="shape overflow-hidden ">
    <svg viewBox="0 0 2880 48" fill="none" style="color:#d3d3d3 ;" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
    </svg>
  </div>
</div>
<!--Shape End-->
  <!--end section-->
  <!-- End -->
  
  <!-- Back to top -->
  <a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
    <i-feather name="arrow-up" class="icons"></i-feather>
  </a>
  <!-- Back to top -->