import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ISalaryCurrency, ISalaryRange } from '../Interfaces/job.interface';
//import { IPagination } from './pagination.interface';

const baseUrl = environment.apiUrl + 'range/salary/';
const baseUrlCurrency = environment.apiUrl + 'currency/salary/';


@Injectable({
  providedIn: 'root'
})
export class SalaryService {

  constructor(private http: HttpClient) { }


  /**
* to get salaryRange by id
* @param id the id number for emp 
* @returns Iemp observable  
*/
  getSalaryRange(id: number): Observable<ISalaryRange> {
    
    return this.http.get<ISalaryRange>(baseUrl + id);
  }
  getSalaryCurrency(id: number): Observable<ISalaryCurrency> {
    
    return this.http.get<ISalaryCurrency>(baseUrlCurrency + id);
  }

  getList(): Observable<ISalaryRange[]> {
    return this.http.get<ISalaryRange[]>(baseUrl);
  }

  getListCurrency(): Observable<ISalaryCurrency[]> {
    return this.http.get<ISalaryCurrency[]>(baseUrlCurrency);
  }

  /**
 * to create new IEmpType to the backend 
 * @param data as a category interface
 * @returns IEmpType object observable
  */
  createCurrency(data: ISalaryCurrency): Observable<ISalaryCurrency> {
    return this.http.post<ISalaryCurrency>(baseUrlCurrency, data);
  }

  create(data: ISalaryRange): Observable<ISalaryRange> {
    return this.http.post<ISalaryRange>(baseUrl, data);
  }


  /**
   * @param id IEmpType id to be updated
   * @param data as a category interface
   * @returns IEmpType object observable
   */
  update(id: number, data: ISalaryRange): Observable<ISalaryRange> {
    return this.http.patch<ISalaryRange>(baseUrl + id, data);
  }

  updateCurrency(id: number, data: ISalaryCurrency): Observable<ISalaryCurrency> {
    return this.http.patch<ISalaryCurrency>(baseUrlCurrency + id, data);
  }

  



  /**
  * Soft Delete IEmpType based on IEmpType ID in parameter
  * @param id IEmpType id to deleted
  * @returns IEmpType object observable
  */
  deleteCurrency(id: string): Observable<ISalaryCurrency> {
    return this.http.delete<ISalaryCurrency>(baseUrlCurrency + id);
  }

  delete(id: string): Observable<ISalaryRange> {
    return this.http.delete<ISalaryRange>(baseUrl + id);
  }
}


