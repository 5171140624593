import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import ICategory, { ISubcategory } from '../Interfaces/category.interface';
//import { IPagination } from './pagination.interface';

const baseUrl = environment.apiUrl + 'category/';
const baseUrlSub = environment.apiUrl + 'subcategory/';


@Injectable({
  providedIn: 'root'
})
export class CategoryService {
  categoryCach = new Map();
  categories: ICategory[];

  constructor(private http: HttpClient) { }

  /**
   * to get Category list
   * @param useCach
   * @returns category object observable
   */
  getCategoryList( useCach: boolean): Observable<ICategory[]> {
    if (useCach === false) {
      this.categoryCach = new Map();
    }

    if (this.categoryCach.size > 0 && useCach === true) {

      if (this.categoryCach.has(Object.values("category").join('-'))) {
        this.categories = this.categoryCach.get(Object.values("category").join('-'));

        return of(this.categories);
      }
    }

    return this.http.get<ICategory[]>(baseUrl)
      .pipe(
        map(response => {
          this.categoryCach.set(Object.values("category").join('-'), response);
          this.categories = response;
          return this.categories;
        })
      );
  }



  getCategoryWithCOuntList(): Observable<ICategory[]> {
    return this.http.get<ICategory[]>(baseUrl+'job')
      .pipe(
        map(response => {
          this.categories = response;
          return this.categories;
        })
      );
  }
  /**
* to get category by id
* @param id the id number for category 
* @returns Icategory observable  
*/
  getCategory(id: number): Observable<ICategory> {
    let category: ICategory;
    this.categoryCach.forEach((categories: ICategory[]) => {
      category = categories.find(p => p.id === id);
    });
    if (category) {
      return of(category);
    }
    return this.http.get<ICategory>(baseUrl + id);
  }


  getList(): Observable<ICategory[]> {
    return this.http.get<ICategory[]>(baseUrl);
  }


  getSubList(): Observable<ISubcategory[]> {
    return this.http.get<ISubcategory[]>(baseUrlSub);
  }
  /**
* to get suBCATEGORY by id
* @param id the id number for category 
* @returns Isubcategory observable  
*/
getSubCategory(id: number): Observable<ISubcategory> {

  return this.http.get<ISubcategory>(baseUrlSub + id);
}

  /**
* to get suBCATEGORY by id
* @param id the id number for category 
* @returns Isubcategory observable  
*/
getSubCategoryBycate(id: number): Observable<ISubcategory[]> {

  return this.http.get<ISubcategory[]>(baseUrlSub+"category/" + id);
}
  /**
 * to create new CATEGORY to the backend 
 * @param data as a category interface
 * @returns category object observable
  */
  create(data: ICategory): Observable<ICategory> {
    return this.http.post<ICategory>(baseUrl, data);
  }

    /**
 * to create new SubCATEGORY to the backend 
 * @param data as a subcategory interface
 * @returns subcategory object observable
  */
     createSub(data: ISubcategory): Observable<ISubcategory> {
      return this.http.post<ISubcategory>(baseUrlSub, data);
    }


      /**
   * @param id SUbCategory id to be updated
   * @param data as a Subcategory interface
   * @returns subcategory object observable
   */
  updateSub(id: number, data: ISubcategory): Observable<ISubcategory> {
    return this.http.patch<ISubcategory>(baseUrlSub + id, data);
  }


  /**
   * @param id Category id to be updated
   * @param data as a category interface
   * @returns category object observable
   */
  update(id: number, data: ICategory): Observable<ICategory> {
    return this.http.patch<ICategory>(baseUrl + id, data);
  }

  

  /**
   * to search in CATEGORY 
   * @param searchText CATEGORY id to be find CATEGORY 
   * @returns CATEGORY object observable
   */
  getFind(searchText: string): Observable<ICategory[]> {

    return this.http.get<ICategory[]>(baseUrl + 'find?search=' + searchText);
  }




  /**
  * Soft Delete CATEGORY based on CATEGORY ID in parameter
  * @param id CATEGORY id to deleted
  * @returns CATEGORY object observable
  */
  delete(id: number): Observable<ICategory> {
    return this.http.delete<ICategory>(baseUrl + id);
  }
  deleteSub(id: number): Observable<ISubcategory> {
    return this.http.delete<ISubcategory>(baseUrlSub + id);
  }

}


