import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { SwiperOptions } from 'swiper';
import { NgbModal, NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-index-real-estate',
  templateUrl: './index-real-estate.component.html',
  styleUrls: ['./index-real-estate.component.css'],
  providers: [NgbCarouselConfig],
  
})



/***
 * Real Estate Component
 */
export class IndexRealEstateComponent implements OnInit {
  

  /***
   * Nav Bg Light Class Add
   */
  navClass = 'nav-light';
  public config: SwiperOptions = {
    a11y: { enabled: true },
    direction: 'horizontal',
    slidesPerView: 1,
    keyboard: true,
    navigation: true,
    pagination: false
  };

  /***
   * Testimonial Slider
   */
  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    autoplay: true,
    navSpeed: 700,
    navText: ['', ''],
    responsive: {
      0: {
        items: 1
      },
      600: {
        items: 2
      },
      900: {
        items: 3
      }
    },
    nav: false,
  };

  /**
   * Review Testimonial Data
   */
  reviewData = [
    {
      profile: "assets/images/real/Director.jpeg",
      name: "Singotam Bhaskar reddy",
      designation: "Director",
      message: `" Avasa Homes A Gated community DTCP Approved  "`
    },
    {
      profile: "assets/images/real/BalReddy.jpg",
      name: "Singotam Bal reddy",
      designation: "Founder",
      message: `" Avasa Homes A Gated community DTCP Approved  "`
    },
    {
      profile: "assets/images/real/MD.JPG",
      name: "Singotam Vishal reddy",
      designation: "M.D",
      message: `" Avasa Homes A Gated community DTCP Approved . "`
    },
   // {
   //   profile: "assets/images/client/04.jpg",
    //  name: "Christa Smith",
    //  designation: "Manager",
     // message: `" According to most sources, Lorum Ipsum can be traced back to a text
      //composed by Cicero in 45 BC. Allegedly, a Latin scholar established the origin of the text by compiling
     // all the instances of the unusual word 'consectetur' he could find "`
    //},
    //{
    //  profile: "assets/images/client/05.jpg",
    //  name: "Dean Tolle",
    //  designation: "Developer",
    //  message: `" It seems that only fragments of the original text remain in the
    //  Lorem Ipsum texts used today. The most well-known dummy text is the 'Lorem Ipsum', which is said to have
    //  originated in the 16th century. "`
  //  },
   // {
     // profile: "assets/images/client/06.jpg",
     // name: "Jill Webb",
     // designation: "Designer",
     // message: `" It seems that only fragments of the original text remain in the
     // Lorem Ipsum texts used today. One may speculate that over the course of time certain letters were added
     // or deleted at various positions within the text. "`
   // }
  ];

  /**
   * Blog Data
   */
  blogData = [
    {
      image: "assets/images/real/p1.jpg",
      title: "Luxury Apartment In Avasa Home",
      like: "33",
      message: "08",
      name: "Sigotam",
      date: "03rd October, 2022"
    },
    {
      image: "assets/images/real/p2.jpg",
      title: "Luxury Apartment In Avasa Home",
      like: "33",
      message: "08",
      name: "Sigotam",
      date: "03rd October, 2022"
    },
    {
      image: "assets/images/real/p3.jpg",
      title: "Luxury Apartment In Avasa Home",
      like: "33",
      message: "08",
      name: "Sigotam",
      date: "03rd October, 2022"
    }
  ];

  constructor(private modalService: NgbModal ) { }

  ngOnInit(): void {
  }
/**
  * Open modal for show the video
  * @param content content of modal
  */
 openModal(content) {
  this.modalService.open(content, { windowClass: 'dark-modal', size: 'lg', centered: true });
}
}
