import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { IEmpType } from '../Interfaces/employee.interface';
//import { IPagination } from './pagination.interface';

const baseUrl = environment.apiUrl + 'employeetype/';


@Injectable({
  providedIn: 'root'
})
export class EmployeeService {
  empCach = new Map();
  empTypes: IEmpType[];

  constructor(private http: HttpClient) { }

  /**
   * to get EmpType list
   * @param useCach
   * @returns EmpType object observable
   */
  getEmpTypeList( useCach: boolean): Observable<IEmpType[]> {
    if (useCach === false) {
      this.empCach = new Map();
    }

    if (this.empCach.size > 0 && useCach === true) {

      if (this.empCach.has(Object.values("emp").join('-'))) {
        this.empTypes = this.empCach.get(Object.values("emp").join('-'));

        return of(this.empTypes);
      }
    }

    return this.http.get<IEmpType[]>(baseUrl)
      .pipe(
        map(response => {
          this.empCach.set(Object.values("emp").join('-'), response);
          this.empTypes = response;
          return this.empTypes;
        })
      );
  }

  /**
* to get emp by id
* @param id the id number for emp 
* @returns Iemp observable  
*/
  getEmployee(id: number): Observable<IEmpType> {
    let category: IEmpType;
    this.empCach.forEach((categories: IEmpType[]) => {
      category = categories.find(p => p.id === id);
    });
    if (category) {
      return of(category);
    }
    return this.http.get<IEmpType>(baseUrl + id);
  }
  getEmployeeType(id: number): Observable<IEmpType> {
   
    return this.http.get<IEmpType>(baseUrl + id);
  }

  getList(): Observable<IEmpType[]> {
    return this.http.get<IEmpType[]>(baseUrl);
  }

  /**
 * to create new IEmpType to the backend 
 * @param data as a category interface
 * @returns IEmpType object observable
  */
  create(data: IEmpType): Observable<IEmpType> {
    return this.http.post<IEmpType>(baseUrl, data);
  }

  


  /**
   * @param id IEmpType id to be updated
   * @param data as a category interface
   * @returns IEmpType object observable
   */
  update(id: number, data: IEmpType): Observable<IEmpType> {
    return this.http.patch<IEmpType>(baseUrl + id, data);
  }

  

  /**
   * to search in CATEGORY 
   * @param searchText CATEGORY id to be find CATEGORY 
   * @returns CATEGORY object observable
   */
  getFind(searchText: string): Observable<IEmpType[]> {

    return this.http.get<IEmpType[]>(baseUrl + 'find?search=' + searchText);
  }




  /**
  * Soft Delete IEmpType based on IEmpType ID in parameter
  * @param id IEmpType id to deleted
  * @returns IEmpType object observable
  */
  delete(id: string): Observable<IEmpType> {
    return this.http.delete<IEmpType>(baseUrl + id);
  }


}


