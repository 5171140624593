import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-service4',
  templateUrl: './service4.component.html',
  styleUrls: ['./service4.component.css']
})
export class Service4Component implements OnInit {
  navClass = 'nav-light';
  constructor(private modalService: NgbModal) { }

  ngOnInit(): void {
  }
/**
  * Open modal for show the video
  * @param content content of modal
  */
 openWindowCustomClass(content) {
  this.modalService.open(content, { windowClass: 'dark-modal', size: 'lg', centered: true });
}
}
