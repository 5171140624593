<!-- Hero Start -->
<section class="bg-half-170 d-table w-100" id="home" style="  background: url(assets/images/shapes/shape2.png) top;
z-index: 0;">
  <div class="container">
    <div class="row mt-5 align-items-center">
      <div class="col-lg-6 col-md-7">
        <div class="title-heading">
          <h1 class="heading mb-3">Manage all of you stuff using <span class="text-primary">Landrick.</span> app</h1>
          <p class="para-desc text-muted">Launch your campaign and benefit from our expertise on designing and managing
            conversion centered bootstrap v5 html page.</p>
          <div class="mt-4">
            <a href="javascript:void(0)" class="btn btn-primary mt-2 me-2"><i class="uil uil-apple"></i> App Store</a>
            <a href="javascript:void(0)" class="btn btn-outline-primary mt-2"><i class="uil uil-google-play"></i> Play
              Store</a>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-6 col-md-5 mt-4 pt-2 mt-sm-0 pt-sm-0">
        <div class="text-md-end text-center ms-lg-4">
          <img src="assets/images/app/home.png" class="img-fluid" alt="">
        </div>
      </div>
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- Hero End -->

<!-- Shape Start -->
<div class="position-relative">
  <div class="shape overflow-hidden text-light">
    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
    </svg>
  </div>
</div>
<!--Shape End-->

<!-- Features Start -->
<section class="section bg-light">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12 text-center">
        <div class="section-title mb-4 pb-2">
          <h4 class="title mb-4">App Features</h4>
          <p class="text-muted para-desc mb-0 mx-auto">Start working with <span
              class="text-primary fw-bold">Landrick</span> that can provide everything you need to generate awareness,
            drive traffic, connect.</p>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->

    <div class="row justify-content-center align-items-center">
      <div class="col-lg-8 col-md-8">
        <div class="row mt-4 pt-2">
          <div class="col-md-6 col-12">
            <div class="d-flex features pt-4 pb-4">
              <div class="icon text-center rounded-circle text-primary me-3 mt-2">
                <i-feather name="monitor" class="fea icon-ex-md text-primary"></i-feather>
              </div>
              <div class="flex-1">
                <h4 class="title">Use On Any Device</h4>
                <p class="text-muted para mb-0">Composed in a pseudo-Latin language which more or less pseudo-Latin
                  language corresponds.</p>
              </div>
            </div>
          </div>
          <!--end col-->

          <div class="col-md-6 col-12">
            <div class="d-flex features pt-4 pb-4">
              <div class="icon text-center rounded-circle text-primary me-3 mt-2">
                <i-feather name="feather" class="fea icon-ex-md text-primary"></i-feather>
              </div>
              <div class="flex-1">
                <h4 class="title">Feather Icons</h4>
                <p class="text-muted para mb-0">Composed in a pseudo-Latin language which more or less pseudo-Latin
                  language corresponds.</p>
              </div>
            </div>
          </div>
          <!--end col-->

          <div class="col-md-6 col-12">
            <div class="d-flex features pt-4 pb-4">
              <div class="icon text-center rounded-circle text-primary me-3 mt-2">
                <i-feather name="eye" class="fea icon-ex-md text-primary"></i-feather>
              </div>
              <div class="flex-1">
                <h4 class="title">Retina Ready</h4>
                <p class="text-muted para mb-0">Composed in a pseudo-Latin language which more or less pseudo-Latin
                  language corresponds.</p>
              </div>
            </div>
          </div>
          <!--end col-->

          <div class="col-md-6 col-12">
            <div class="d-flex features pt-4 pb-4">
              <div class="icon text-center rounded-circle text-primary me-3 mt-2">
                <i-feather name="user-check" class="fea icon-ex-md text-primary"></i-feather>
              </div>
              <div class="flex-1">
                <h4 class="title">W3c Valid Code</h4>
                <p class="text-muted para mb-0">Composed in a pseudo-Latin language which more or less pseudo-Latin
                  language corresponds.</p>
              </div>
            </div>
          </div>
          <!--end col-->

          <div class="col-md-6 col-12">
            <div class="d-flex features pt-4 pb-4">
              <div class="icon text-center rounded-circle text-primary me-3 mt-2">
                <i-feather name="smartphone" class="fea icon-ex-md text-primary"></i-feather>
              </div>
              <div class="flex-1">
                <h4 class="title">Fully Responsive</h4>
                <p class="text-muted para mb-0">Composed in a pseudo-Latin language which more or less pseudo-Latin
                  language corresponds.</p>
              </div>
            </div>
          </div>
          <!--end col-->

          <div class="col-md-6 col-12">
            <div class="d-flex features pt-4 pb-4">
              <div class="icon text-center rounded-circle text-primary me-3 mt-2">
                <i-feather name="heart" class="fea icon-ex-md text-primary"></i-feather>
              </div>
              <div class="flex-1">
                <h4 class="title">Browser Compatibility</h4>
                <p class="text-muted para mb-0">Composed in a pseudo-Latin language which more or less pseudo-Latin
                  language corresponds.</p>
              </div>
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end col-->

      <div class="col-lg-4 col-md-4 col-12 mt-4 pt-2 text-center text-md-end">
        <img src="assets/images/app/feature.png" class="img-fluid" alt="">
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- Feature End -->

<!-- Showcase Start -->
<section class="section pt-0 bg-light">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12 text-center">
        <div class="section-title mb-4 pb-2">
          <h4 class="title mb-4">How Can We Help You ?</h4>
          <p class="text-muted para-desc mb-0 mx-auto">Start working with <span
              class="text-primary fw-bold">Landrick</span> that can provide everything you need to generate awareness,
            drive traffic, connect.</p>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->

    <div class="row align-items-center">
      <div class="col-lg-5 col-md-6 mt-4 pt-2">
        <img src="assets/images/app/4.png" class="img-fluid mx-auto d-block" alt="">
      </div>
      <!--end col-->

      <div class="col-lg-7 col-md-6 mt-4 pt-2">
        <div class="section-title ms-lg-5">
          <h4 class="title mb-4">Best <span class="text-primary">Landrick</span> App Partner For Your Life</h4>
          <p class="text-muted">You can combine all the Landrick templates into a single one, you can take a component
            from the Application theme and use it in the Website.</p>
          <ul class="list-unstyled text-muted">
            <li class="mb-0"><span class="text-primary h5 me-2"><i
                  class="uil uil-check-circle align-middle"></i></span>Digital Marketing Solutions for Tomorrow</li>
            <li class="mb-0"><span class="text-primary h5 me-2"><i
                  class="uil uil-check-circle align-middle"></i></span>Our Talented & Experienced Marketing Agency</li>
            <li class="mb-0"><span class="text-primary h5 me-2"><i
                  class="uil uil-check-circle align-middle"></i></span>Create your own skin to match your brand</li>
          </ul>
          <a href="javascript:void(0)" class="mt-3 h6 text-primary">Find Out More <i
              class="uil uil-angle-right-b"></i></a>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->

  <div class="container mt-100 mt-60">
    <div class="row justify-content-center">
      <div class="col-12 text-center">
        <div class="section-title mb-4 pb-2">
          <h4 class="title mb-4"><span class="text-primary">Landrick</span> App Showcase</h4>
          <p class="text-muted para-desc mb-0 mx-auto">Start working with <span
              class="text-primary fw-bold">Landrick</span> that can provide everything you need to generate awareness,
            drive traffic, connect.</p>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->

    <div class="row justify-content-center">
      <div class=" mt-4 pt-2 text-center">
        <ul ngbNav #nav="ngbNav"
          class="nav-pills nav-justified flex-column flex-sm-row rounded col-lg-8 col-md-12 offset-md-2">
          <li ngbNavItem>
            <a ngbNavLink class="rounded">
              <div class="text-center py-2">
                <h6 class="mb-0">High Performance</h6>
              </div>
            </a>
            <ng-template ngbNavContent>
              <div class="row align-items-center">
                <div class="col-md-6">
                  <img src="assets/images/app/2.png" class="img-fluid mx-auto d-block" alt="">
                </div>
                <!--end col-->

                <div class="col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
                  <div class="section-title text-md-start">
                    <h4 class="title mb-4"><i class="uil uil-angle-double-right text-primary"></i> High Performing
                      Landing App</h4>
                    <p class="text-muted">You can combine all the Landrick templates into a single one, you can take a
                      component from the Application theme and use it in the Website.</p>
                    <ul class="list-unstyled text-muted">
                      <li class="mb-0"><span class="text-primary h5 me-2"><i
                            class="uil uil-check-circle align-middle"></i></span>Digital Marketing Solutions for
                        Tomorrow</li>
                      <li class="mb-0"><span class="text-primary h5 me-2"><i
                            class="uil uil-check-circle align-middle"></i></span>Our Talented & Experienced Marketing
                        Agency</li>
                      <li class="mb-0"><span class="text-primary h5 me-2"><i
                            class="uil uil-check-circle align-middle"></i></span>Create your own skin to match your
                        brand</li>
                    </ul>
                    <a href="javascript:void(0)" class="mt-3 h6 text-primary">Find Out More <i
                        class="uil uil-angle-right-b"></i></a>
                  </div>
                </div>
                <!--end col-->
              </div>
              <!--end row-->
            </ng-template>
          </li>
          <li ngbNavItem>
            <a ngbNavLink class="rounded">
              <div class="text-center py-2">
                <h6 class="mb-0">Creative Design</h6>
              </div>
            </a>
            <ng-template ngbNavContent>
              <div class="row align-items-center">
                <div class="col-md-6">
                  <img src="assets/images/app/3.png" class="img-fluid mx-auto d-block" alt="">
                </div>
                <!--end col-->

                <div class="col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
                  <div class="section-title text-md-start">
                    <h4 class="title mb-4"><i class="uil uil-angle-double-right text-primary"></i> Creative Design and
                      Clean Code</h4>
                    <p class="text-muted">You can combine all the Landrick templates into a single one, you can take a
                      component from the Application theme and use it in the Website.</p>
                    <ul class="list-unstyled text-muted">
                      <li class="mb-0"><span class="text-primary h5 me-2"><i
                            class="uil uil-check-circle align-middle"></i></span>Digital Marketing Solutions for
                        Tomorrow</li>
                      <li class="mb-0"><span class="text-primary h5 me-2"><i
                            class="uil uil-check-circle align-middle"></i></span>Our Talented & Experienced Marketing
                        Agency</li>
                      <li class="mb-0"><span class="text-primary h5 me-2"><i
                            class="uil uil-check-circle align-middle"></i></span>Create your own skin to match your
                        brand</li>
                    </ul>
                    <a href="javascript:void(0)" class="mt-3 h6 text-primary">Find Out More <i
                        class="uil uil-angle-right-b"></i></a>
                  </div>
                </div>
                <!--end col-->
              </div>
              <!--end row-->
            </ng-template>
          </li>
          <li ngbNavItem>
            <a ngbNavLink class="rounded">
              <div class="text-center py-2">
                <h6 class="mb-0">24 / 7 Support</h6>
              </div>
            </a>
            <ng-template ngbNavContent>
              <div class="row align-items-center">
                <div class="col-md-6">
                  <img src="assets/images/app/4.png" class="img-fluid mx-auto d-block" alt="">
                </div>
                <!--end col-->

                <div class="col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
                  <div class="section-title text-md-start">
                    <h4 class="title mb-4"><i class="uil uil-angle-double-right text-primary"></i> 24 / 7 App Supports
                      and Responsive</h4>
                    <p class="text-muted">You can combine all the Landrick templates into a single one, you can take a
                      component from the Application theme and use it in the Website.</p>
                    <ul class="list-unstyled text-muted">
                      <li class="mb-0"><span class="text-primary h5 me-2"><i
                            class="uil uil-check-circle align-middle"></i></span>Digital Marketing Solutions for
                        Tomorrow</li>
                      <li class="mb-0"><span class="text-primary h5 me-2"><i
                            class="uil uil-check-circle align-middle"></i></span>Our Talented & Experienced Marketing
                        Agency</li>
                      <li class="mb-0"><span class="text-primary h5 me-2"><i
                            class="uil uil-check-circle align-middle"></i></span>Create your own skin to match your
                        brand</li>
                    </ul>
                    <a href="javascript:void(0)" class="mt-3 h6 text-primary">Find Out More <i
                        class="uil uil-angle-right-b"></i></a>
                  </div>
                </div>
                <!--end col-->
              </div>
              <!--end row-->
            </ng-template>
          </li>
        </ul>
        <div [ngbNavOutlet]="nav" class="col-12 mt-4 pt-2"></div>
      </div>
    </div>
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- Showcase End -->

<!-- Shape Start -->
<div class="position-relative">
  <div class="shape overflow-hidden text-white">
    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
    </svg>
  </div>
</div>
<!--Shape End-->

<!-- Price Start -->
<section class="section">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12 text-center">
        <div class="section-title mb-4 pb-2">
          <h4 class="title mb-4">Choose The Pricing Plan</h4>
          <p class="text-muted para-desc mb-0 mx-auto">Start working with <span
              class="text-primary fw-bold">Landrick</span> that can provide everything you need to generate awareness,
            drive traffic, connect.</p>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->

    <app-simple-pricing [simple_pricingData]="simple_pricingData"></app-simple-pricing>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- Price End -->

<!-- Testi n Download cta start -->
<section class="section pt-0">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <owl-carousel-o id="customer-testi" [options]="customOptions">
          <ng-template carouselSlide>
            <div class="card customer-testi border-0 text-center">
              <div class="card-body">
                <img src="assets/images/client/01.jpg"
                  class="img-fluid avatar avatar-small rounded-circle mx-auto shadow" alt="">
                <p class="text-muted mt-4">" It seems that only fragments of the original text remain in the Lorem Ipsum
                  texts used today. "</p>
                <h6 class="text-primary">- Thomas Israel</h6>
              </div>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="card customer-testi border-0 text-center">
              <div class="card-body">
                <img src="assets/images/client/02.jpg"
                  class="img-fluid avatar avatar-small rounded-circle mx-auto shadow" alt="">
                <p class="text-muted mt-4">" The most well-known dummy text is the 'Lorem Ipsum', which is said to have
                  originated in the 16th century. "</p>
                <h6 class="text-primary">- Carl Oliver</h6>
              </div>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="card customer-testi border-0 text-center">
              <div class="card-body">
                <img src="assets/images/client/03.jpg"
                  class="img-fluid avatar avatar-small rounded-circle mx-auto shadow" alt="">
                <p class="text-muted mt-4">" One disadvantage of Lorum Ipsum is that in Latin certain letters appear
                  more frequently than others. "</p>
                <h6 class="text-primary">- Barbara McIntosh</h6>
              </div>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="card customer-testi border-0 text-center">
              <div class="card-body">
                <img src="assets/images/client/04.jpg"
                  class="img-fluid avatar avatar-small rounded-circle mx-auto shadow" alt="">
                <p class="text-muted mt-4">" Thus, Lorem Ipsum has only limited suitability as a visual filler for
                  German texts. "</p>
                <h6 class="text-primary">- Jill Webb</h6>
              </div>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="card customer-testi border-0 text-center">
              <div class="card-body">
                <img src="assets/images/client/05.jpg"
                  class="img-fluid avatar avatar-small rounded-circle mx-auto shadow" alt="">
                <p class="text-muted mt-4">" There is now an abundance of readable dummy texts. These are usually used
                  when a text is required. "</p>
                <h6 class="text-primary">- Dean Tolle</h6>
              </div>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="card customer-testi border-0 text-center">
              <div class="card-body">
                <img src="assets/images/client/06.jpg"
                  class="img-fluid avatar avatar-small rounded-circle mx-auto shadow" alt="">
                <p class="text-muted mt-4">" According to most sources, Lorum Ipsum can be traced back to a text
                  composed by Cicero. "</p>
                <h6 class="text-primary">- Christa Smith</h6>
              </div>
            </div>
          </ng-template>
        </owl-carousel-o>
        <!-- </div> -->
      </div>
      <!--end col-->
    </div>
    <!--end row-->

    <div class="row mt-md-5 pt-md-3 mt-4 pt-2 mt-sm-0 pt-sm-0 justify-content-center">
      <div class="col-12 text-center">
        <div class="section-title">
          <h4 class="title mb-4">Get the App now !</h4>
          <p class="text-muted para-desc mx-auto">Start working with <span class="text-primary fw-bold">Landrick</span>
            that can provide everything you need to generate awareness, drive traffic, connect.</p>
          <div class="mt-4">
            <a href="javascript:void(0)" class="btn btn-primary m-1"><i class="uil uil-apple"></i> App Store</a>
            <a href="javascript:void(0)" class="btn btn-outline-primary m-1"><i class="uil uil-google-play"></i> Play
              Store</a>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- Testi n Download cta End -->

<!-- Shape Start -->
<div class="position-relative">
  <div class="shape overflow-hidden text-footer">
    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
    </svg>
  </div>
</div>
<!--Shape End-->


<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
  <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->