import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { IJob, IJobExp, IJobQuali, IJobSkill, IJobSkillR, IJobType } from '../Interfaces/job.interface';
//import { IPagination } from './pagination.interface';

const baseUrl = environment.apiUrl + 'jobtype/';
const baseUrlQuali = environment.apiUrl + 'jobqualification/';
const baseUrlSkill = environment.apiUrl + 'skill/';
const baseUrlJob = environment.apiUrl + 'job/';
const baseUrlJobSkill= environment.apiUrl + 'jobskill/'
const baseUrlExp = environment.apiUrl + 'jobexp/';

@Injectable({
  providedIn: 'root'
})
export class JobService {
  jobCach = new Map();
  jobTypes: IJobType[];

  constructor(private http: HttpClient) { }

  /**
   * to get JobType list
   * @param useCach
   * @returns JObType object observable
   */
  getJobTypeList( useCach: boolean): Observable<IJobType[]> {
    if (useCach === false) {
      this.jobCach = new Map();
    }

    if (this.jobCach.size > 0 && useCach === true) {

      if (this.jobCach.has(Object.values("job").join('-'))) {
        this.jobTypes = this.jobCach.get(Object.values("job").join('-'));

        return of(this.jobTypes);
      }
    }

    return this.http.get<IJobType[]>(baseUrl)
      .pipe(
        map(response => {
          this.jobCach.set(Object.values("job").join('-'), response);
          this.jobTypes = response;
          return this.jobTypes;
        })
      );
  }

  getLastJob():Observable<IJob>
  {

    return this.http.get<IJob>(baseUrlJob + 'last');
  }
  /**
* to get job by id
* @param id the id number for jobt 
* @returns IjobType observable  
*/
  getJobTypes(id: number): Observable<IJobType> {
    let category: IJobType;
    this.jobCach.forEach((categories: IJobType[]) => {
      category = categories.find(p => p.id === id);
    });
    if (category) {
      return of(category);
    }
    return this.http.get<IJobType>(baseUrl + id);
  }


    /**
* to get job by id
* @param id the id number for jobt 
* @returns IjobExp observable  
*/
getJobExp(id: number): Observable<IJobExp> {
 
  return this.http.get<IJobExp>(baseUrlExp + id);
}


    /**
* to get job by id
* @param id the id number for jobt 
* @returns Ijob observable  
*/
getJob(id: number): Observable<IJob> {
 
  return this.http.get<IJob>(baseUrlJob + id);
}

    /**
* to get job by id
* @param id the id number for IjobQuali 
* @returns IjobQuali observable  
*/
getJobQual(id: number): Observable<IJobQuali> {
 
  return this.http.get<IJobQuali>(baseUrlQuali + id);
}

    /**
* to get IJobSkill by id
* @param id the id number for IJobSkill 
* @returns IJobSkill observable  
*/
getJobSkill(id: number): Observable<IJobSkill> {
 
  return this.http.get<IJobSkill>(baseUrlSkill + id);
}

  getList(): Observable<IJobType[]> {
    return this.http.get<IJobType[]>(baseUrl);
  }

  
  getListExp(): Observable<IJobExp[]> {
    return this.http.get<IJobExp[]>(baseUrlExp);
  }

  
  getListQuali(): Observable<IJobQuali[]> {
    return this.http.get<IJobQuali[]>(baseUrlQuali);
  }

  getListSkill(): Observable<IJobSkill[]> {
    return this.http.get<IJobSkill[]>(baseUrlSkill);
  }

  
  getListJob(): Observable<IJob[]> {
    return this.http.get<IJob[]>(baseUrlJob);
  }

  getLimitJobList(limit:number): Observable<IJob[]> {
    return this.http.get<IJob[]>(baseUrlJob+'limit/'+limit);
  }
  /**
 * to create new IEmpType to the backend 
 * @param data as a category interface
 * @returns IEmpType object observable
  */
  create(data: IJobType): Observable<IJobType> {
    return this.http.post<IJobType>(baseUrl, data);
  }


  createJobS(data: IJobSkillR): Observable<IJobSkillR> {
    return this.http.post<IJobSkillR>(baseUrlJobSkill, data);
  }



   /**
 * to create new IJobQuali to the backend 
 * @param data as a category interface
 * @returns IJobQuali object observable
  */
    createQuali(data: IJobQuali): Observable<IJobQuali> {
      return this.http.post<IJobQuali>(baseUrlQuali, data);
    }

   /**
 * to create new IJobExp to the backend 
 * @param data as a IJobExp interface
 * @returns IJobExp object observable
  */
    createExp(data: IJobExp): Observable<IJobExp> {
      return this.http.post<IJobExp>(baseUrlExp, data);
    }


      /**
 * to create new IJobSkill to the backend 
 * @param data as a IJobSkill interface
 * @returns IJobSkill object observable
  */
       createSkill(data: IJobSkill): Observable<IJobSkill> {
        return this.http.post<IJobSkill>(baseUrlSkill, data);
      }
  
      createJob(data: IJob): Observable<IJob> {
        return this.http.post<IJob>(baseUrlJob +'new', data);
      }

  /**
   * @param id IJobType id to be updated
   * @param data as a category interface
   * @returns IJobType object observable
   */
  update(id: number, data: IJobType): Observable<IJobType> {
    return this.http.patch<IJobType>(baseUrl + id, data);
  }
  uploadPhoto(photo: File): Observable<any> {
    let filePhoto: FormData = new FormData();
    filePhoto.append('files', photo, photo.name);
    return this.http.post<any>(baseUrlJob + 'upload', filePhoto);
  }




   /**
   * @param id IJobExp id to be updated
   * @param data as a IJobExp interface
   * @returns IJobExp object observable
   */
    updateExp(id: number, data: IJobExp): Observable<IJobExp> {
      return this.http.patch<IJobExp>(baseUrlExp + id, data);
    }

     /**
   * @param id IJobSkill id to be updated
   * @param data as a IJobSkill interface
   * @returns IJobSkill object observable
   */
      updateSkill(id: number, data: IJobSkill): Observable<IJobSkill> {
        return this.http.patch<IJobSkill>(baseUrlSkill + id, data);
      }


     /**
   * @param id IJobQuali id to be updated
   * @param data as a IJobQuali interface
   * @returns IJobQuali object observable
   */
      updateQuali(id: number, data: IJobQuali): Observable<IJobQuali> {
        return this.http.patch<IJobQuali>(baseUrlQuali + id, data);
      }
  
      updateJob(id: number, data: IJob): Observable<IJob> {
        return this.http.patch<IJob>(baseUrlJob + id, data);
      }
  

  /**
   * to search in IJobType 
   * @param searchText IJobType id to be find IJobType 
   * @returns IJobType object observable
   */
  getFind(searchText: string): Observable<IJobType[]> {

    return this.http.get<IJobType[]>(baseUrl + 'find?search=' + searchText);
  }


  getJobBycompany(id: number): Observable<IJob[]> {

    return this.http.get<IJob[]>(baseUrlJob+"company/" + id);
  }
  getJobBycategory(id: number): Observable<IJob[]> {

    return this.http.get<IJob[]>(baseUrlJob+"category/" + id);
  }

  /**
  * Soft Delete IJobType based on IJobType ID in parameter
  * @param id IJobType id to deleted
  * @returns IJobType object observable
  */
  delete(id: number): Observable<IJobType> {
    return this.http.delete<IJobType>(baseUrl + id);
  }

  deleteJob(id: number): Observable<IJob> {
    return this.http.delete<IJob>(baseUrlJob + id);
  }


}


